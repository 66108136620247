/* istanbul ignore file */

export const baseUrl = window.location.origin;

export const IS_BOTOX_DOMAIN = baseUrl.includes('//botox.');

export const __DEV__ = process.env.NODE_ENV === 'development';

export const isInMaintenanceMode =
  process.env.REACT_APP_OPTIMIZELY_FEATURE_OVERRIDES === 'all_maintenance_page';

export const isAlleDown = process.env.REACT_APP_ALLE_DOWN === 'true';

export const cookiesSettings = {
  name: process.env.REACT_APP_COOKIES_NAME || 'alleConsent',
  maxAge: +(process.env.REACT_APP_COOKIES_MAX_AGE || 31536000),
};
export const ONETRUST_COOKIE_NAME = 'OptanonAlertBoxClosed';
