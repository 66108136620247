enum Routes {
  'about' = 'https://alle.com/about',
  'home' = '/',
  'globalIsi' = 'https://alle.com/global-isi',
  'howItWorks' = 'https://alle.com/how-alle-works',
  'login' = 'https://alle.com/login',
  'settingsPrivacy' = 'https://alle.com/settings/privacy',
  'termsAndConditions' = 'https://alle.com/terms-and-conditions',
}

export { Routes };
