enum colors {
  accent = '#a7a9ac',
  black = '#111111',
  lightGray = '#D1D1D1',
  darkGray = '#252525',
  gray1 = '#888888',
  gray79 = '#C9C9C9',
  gray95 = '#f2f2f2',
  gray100 = '#6a6a6a',
  gray200 = '#e6e6e6',
  gray300 = '#f8f8f8',
  white = '#ffffff',
  error = '#ff0000',
  general = '#007BA4',
  positive = '#80BE40',
  positive2 = '#008A1A',
  warning = '#FE6100',
  warning2 = '#EFA200',
  negative = '#C53425',
  green = '#24CA39',
  green2 = '#D8FFE0',
  green3 = '#f6fcf6',
  nude = '#F5E0D8',
  nudeDark = '#A65E4F',
  nudeDark2 = '#824F45',
  skin = '#D09284',
  skin2 = '#C98373',
  bone = '#FBF6F5',
  shilo = '#E0AFA4',
  coralThree = '#B57466',
  bole = '#72483E',
  demoWarning = '#800000',
  errorRed = '#FCECE9',
  errorRed2 = '#611A17',
  errorRed3 = '#f44336',
  pink = '#FCE6E6',
  blue1 = '#216BA8',
  facebookBlue = '#4267B2',

  //temp botox day hotfix
  goldBotoxFont = '#CAA347',
  rustBotoxFont = '#C68430',
}

export { colors };
