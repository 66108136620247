import 'react-app-polyfill/stable';
import 'intersection-observer'; // polyfill needed for use-is-in-viewport
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppRouter } from './router';
import * as serviceWorker from './serviceWorker';
import { CSSProp } from 'styled-components';
import { Theme } from '@allergan-data-labs/universal-component-library/src';

// // Separate if statements in order for webpack to strip out react-axe in production
// if (process.env.NODE_ENV !== 'production') {
//   if (process.env.REACT_APP_BROWSER_ACCESSIBILITY_TESTING === 'true') {
//     const axe = require('@axe-core/react');
//     axe(React, ReactDOM, 1000);
//   }
// }

declare module 'react' {
  interface Attributes {
    css?: CSSProp<Theme>;
  }
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppRouter />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
