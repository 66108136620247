import React, { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';

import styled, { keyframes } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ALLERGAN_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';
import {
  above,
  screenSizes,
} from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';

// Components
import { AlleButton as Button } from '@allergan-data-labs/component-library/src/core/alleButton';
import { Close } from '@allergan-data-labs/component-library/src/icons/close';
import { SimpleIconButton } from '@allergan-data-labs/component-library/src/core/simpleIconButton';
import { alleColors } from '@allergan-data-labs/component-library/src/colorPalette';
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import { CookieNotificationBannerDEPRECATED } from './cookieNotificationBannerDEPRECATED';

const showAnimation = (x: string, y: string) => keyframes`
  from { transform: translate(${x}, ${y}); }
  to { transform: translate(${x}, 0); }
`;

const Container = styled.div`
  z-index: 999;
  box-sizing: border-box;
  background-color: ${alleColors.white};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  position: fixed;
  transform: none;
  transition: height 0.5s ease-out;
  left: 0;
  right: 0;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2);
  ${above.desktop`
    flex-direction: row;
    justify-content: flex-start;
    padding: 24px 32px;
  `}
  &.show {
    bottom: 0;
    animation: ${showAnimation('0', '200px')} linear 0.5s;
    ${above.desktop`
      animation: ${showAnimation('0', '100px')} linear 0.5s;
    `}
  }
  &.hide {
    bottom: -510px;
  }
`;

const StyledLink = styled.a`
  vertical-align: baseline;
  color: ${alleColors.black};
  text-decoration: underline;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  && {
    width: 100%;
    max-width: 380px;
    padding: 8px;
    height: 40px;
    margin: 16px auto auto auto;
    ${above.desktop`
      min-width: 125px;
      max-width: 125px;
      margin: auto auto auto 56px;
    `}
  }
`;

const StyledNormalText = styled(Typography)`
  text-align: left;
  margin: 0;
  ${above.mobile`
    margin: 0 25px;
  `}
  ${above.desktop`
    margin: 0;
  `}
`;

const StyledLinkButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  background-color: transparent;
  text-decoration: underline;
  & ${StyledNormalText} {
    margin: 0;
  }
  color: ${alleColors.black};
`;

const CloseButtonContainer = styled.div`
  height: 100%;
  margin-left: 16px;
  ${above.desktop`
    margin-left: 64px;
  `}
`;

const MobileContentWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

interface CookieNotificationBannerProps {
  name: string;
  maxAge: number;
  isCookieBannerEnabled?: boolean;
  isLoggedIn?: boolean;
}

interface ContentBannerProps {
  showFullBanner: boolean;
  onShowMoreClick: () => void;
  onCloseBannerClick: () => void;
  onCookieSettingsClick: () => void;
  onPrivacyPolicyClick: () => void;
}

interface ContentBannerButtonProps {
  showFullBanner: boolean;
  onClick: () => void;
  onPrivacyPolicyClick: () => void;
}

const ShowMoreButton: React.FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledLinkButton onClick={onClick}>
      <StyledNormalText preset="body-M-regular">read more</StyledNormalText>
    </StyledLinkButton>
  );
};

const ShowLessButton: React.FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledLinkButton onClick={onClick}>
      <StyledNormalText preset="body-M-regular">read less</StyledNormalText>
    </StyledLinkButton>
  );
};

const BannerButton: React.FunctionComponent<{
  onClick: () => void;
  label: string;
  buttonType: 'contained' | 'outlined';
  testId: string;
}> = ({ onClick, label, buttonType, testId }) => {
  return (
    <StyledButton
      data-testid={testId}
      buttonType={buttonType}
      marginTop
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CloseButtonContainer>
      <SimpleIconButton
        data-testid={`banner-close-button`}
        aria-label="close-button"
        color={alleColors.gray[400]}
        onClick={onClick}
      >
        <Close />
      </SimpleIconButton>
    </CloseButtonContainer>
  );
};

const Snippet: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      AbbVie may use and disclose online usage data about you collected
      automatically through online tracking technologies such as cookies,
      pixels, and web beacons (collectively, “cookies”). We use this data for
      multiple purposes, including for online targeted advertising
      (advertisements...
      {!showFullBanner && <ShowMoreButton onClick={onClick} />}
    </StyledNormalText>
  );
};

const Content = ({
  onClick,
  onPrivacyPolicyClick,
}: {
  onClick: () => void;
  onPrivacyPolicyClick: () => void;
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      AbbVie may use and disclose online usage data about you collected
      automatically through online tracking technologies such as cookies,
      pixels, and web beacons (collectively, “cookies”). We use this data for
      multiple purposes, including for online targeted advertising
      (advertisements based on your interests inferred from your activity across
      other unaffiliated sites and services) and website analytics purposes, as
      well as to personalize content, save your preferences, provide social
      media features, and track the site’s performance, as further described in
      the{' “'}
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href={ALLERGAN_URLS.cookiesAndTracking}
        onClick={onPrivacyPolicyClick}
      >
        Cookies and similar tracking and data collection technologies
      </StyledLink>
      {'” '}
      section of our Privacy Notice. We retain this data for as long as
      necessary to fulfill these purposes or as needed to comply with our record
      retention obligations. We do not sell your data, but we may disclose it to
      our marketing and advertising partners for purposes of online targeted
      advertising or for website analytics purposes. To opt out of the use or
      disclosure of your cookie-based personal data for online targeted
      advertising or to otherwise manage your preferences, please click on
      Cookie Settings. For additional information on the categories of data we
      collect, the purposes for their collection, disclosures to third parties,
      and data retention, please visit our{' '}
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href={ALLERGAN_URLS.unitedStatesPrivacyPolicy}
        onClick={onPrivacyPolicyClick}
      >
        Privacy Notice
      </StyledLink>
      {'. '}
      <ShowLessButton onClick={onClick} />
    </StyledNormalText>
  );
};

const DesktopBanner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onCookieSettingsClick,
  onCloseBannerClick,
  onPrivacyPolicyClick,
}) => {
  return (
    <>
      {showFullBanner ? (
        <Content
          onClick={onShowMoreClick}
          onPrivacyPolicyClick={onPrivacyPolicyClick}
        />
      ) : (
        <Snippet
          showFullBanner={showFullBanner}
          onClick={onShowMoreClick}
          onPrivacyPolicyClick={onPrivacyPolicyClick}
        />
      )}
      <BannerButton
        onClick={onCookieSettingsClick}
        testId={'cookie-settings-button'}
        buttonType={'outlined'}
        label={'COOKIE SETTINGS'}
      />
      <CloseButton onClick={onCloseBannerClick} />
    </>
  );
};

const MobileBanner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onCookieSettingsClick,
  onCloseBannerClick,
  onPrivacyPolicyClick,
}) => {
  return (
    <>
      {showFullBanner ? (
        <MobileContentWrapper>
          <Content
            onClick={onShowMoreClick}
            onPrivacyPolicyClick={onPrivacyPolicyClick}
          />
          <CloseButton onClick={onCloseBannerClick} />
        </MobileContentWrapper>
      ) : (
        <MobileContentWrapper>
          <Snippet
            showFullBanner={showFullBanner}
            onClick={onShowMoreClick}
            onPrivacyPolicyClick={onPrivacyPolicyClick}
          />{' '}
          <CloseButton onClick={onCloseBannerClick} />
        </MobileContentWrapper>
      )}
      <BannerButton
        onClick={onCookieSettingsClick}
        testId={'cookie-settings-button'}
        buttonType={'outlined'}
        label={'COOKIE SETTINGS'}
      />
    </>
  );
};

const Banner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onCookieSettingsClick,
  onCloseBannerClick,
  onPrivacyPolicyClick,
}) => {
  const isDesktop = useMediaQuery(`(min-width:${screenSizes.desktop}px)`);
  return isDesktop ? (
    <DesktopBanner
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onCookieSettingsClick={onCookieSettingsClick}
      onCloseBannerClick={onCloseBannerClick}
      onPrivacyPolicyClick={onPrivacyPolicyClick}
    />
  ) : (
    <MobileBanner
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onCookieSettingsClick={onCookieSettingsClick}
      onCloseBannerClick={onCloseBannerClick}
      onPrivacyPolicyClick={onPrivacyPolicyClick}
    />
  );
};

const CookieNotificationBanner: React.FunctionComponent<
  CookieNotificationBannerProps
> = ({ name, maxAge, isCookieBannerEnabled, isLoggedIn }): any => {
  const [cookies] = useCookies([name]);
  const [showCookie, setShowCookie] = useState(!cookies[name]);
  const [showFullBanner, setShowFullBanner] = useState(false);

  const { trackConsumer } = useSegment();

  const handlePrivacyPolicyClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Link',
        explainer: 'Tracking if user interacts with OneTrust Banner',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      navigation_type: 'browse',
      site_type: 'consumer web',
      text: 'Privacy Notice',
      url: 'https://privacy.abbvie/',
    });
  }, [isLoggedIn, trackConsumer]);

  return !cookies[name] && isCookieBannerEnabled ? (
    <Container
      className={showCookie ? 'show' : 'hide'}
      data-testid="cookie-notification-banner-container"
      role="region"
      aria-label="Cookie Banner"
    >
      <Banner
        showFullBanner={showFullBanner}
        onShowMoreClick={() => {
          setShowFullBanner(!showFullBanner);
        }}
        onPrivacyPolicyClick={handlePrivacyPolicyClick}
        onCookieSettingsClick={() => {
          (window as any)?.OneTrust?.ToggleInfoDisplay();
          setShowCookie(false);
          trackConsumer()?.siteNavigated({
            event_source_url: `${window.location.href}`,
            navigation_type: 'cookie banner',
            event: {
              action_source: 'consumer',
              activator: 'Cookie Banner',
              explainer: 'Tracking user interaction with cookie banner',
              invoked_by: 'consumer',
              event_type: 'privacy',
              user_type: 'consumer',
              user_status: isLoggedIn ? 'logged_in' : 'logged_out',
            },
          });
        }}
        onCloseBannerClick={() => {
          setShowCookie(false);
          (window as any)?.OneTrust?.Close();
          trackConsumer()?.consentApproved({
            consent_id: 'na',
            consent_type: ['cookies'],
            event: {
              action_source: 'consumer',
              activator: 'Cookie Banner',
              explainer: 'Tracking users interaction with cookie banner',
              invoked_by: 'consumer',
              event_type: 'privacy',
              user_type: 'consumer',
              user_status: 'logged out',
            },
          });
        }}
      />
    </Container>
  ) : (
    <CookieNotificationBannerDEPRECATED name={name} maxAge={maxAge} />
  );
};

export { CookieNotificationBanner };
