import { css } from 'styled-components';
// Example from https://www.styled-components.com/docs/advanced#media-templates
// https://jsramblings.com/2018/02/04/styled-components-media-queries.html
const screenSizes: { [key: string]: number } = {
  largeDesktop: 1440,
  desktop: 1024,
  tablet: 768,
  mobileLarge: 480,
  mobile: 425,
};

// For use in web components only
const above: {
  largeDesktop: any;
  desktop: any;
  tablet: any;
  mobile: any;
} = Object.keys(screenSizes).reduce((acc: any, label: string) => {
  // @ts-ignore
  acc[label] = (...args: any) => css`
    @media (min-width: ${screenSizes[label] / 16}em) {
      ${
        // @ts-ignore
        css(...args)
      }
    }
  `;

  return acc;
}, {});

export { screenSizes, above };
