import React from 'react';
import { GlobalLoadingBar } from '@allergan-data-labs/component-library/src/loadingBar/globalLoadingBar';
import { GlobalStyle } from '@allergan-data-labs/component-library/src/core/globalStyle';
import { CookieNotificationBanner } from '@allergan-data-labs/consumer-component-library/src/cookieNotificationBanner/cookieNotificationBanner';
import { ONETRUST_COOKIE_NAME, cookiesSettings } from './application.config';

const App: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <GlobalLoadingBar />
      <CookieNotificationBanner
        name={ONETRUST_COOKIE_NAME}
        maxAge={cookiesSettings.maxAge}
        isCookieBannerEnabled={true}
        isLoggedIn={false}
      />
      {children}
    </>
  );
};

export { App };
