export const CLOUDINARY_CLOUD_NAME = 'allergan';
export const CNAME = 'media.alle.com';

const segmentPublicKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const __CMS_PREVIEW__ =
  process.env.REACT_APP_CMS_HOST === 'preview.contentful.com';

export const __SEGMENT_DEBUGGER_ENABLED__ =
  process.env.REACT_APP_SEGMENT_DEBUGGER === 'enabled';

// Add key here for Storybook useage
const REACT_APP_GOOGLE_MAPS_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_KEY ||
  'AIzaSyCkfgED4jkFS9RAH4ZQYFbU2PSXvOVfXHc';

export { segmentPublicKey, __CMS_PREVIEW__, REACT_APP_GOOGLE_MAPS_KEY };
