import ApolloClient, { PresetConfig, Resolvers } from 'apollo-boost';

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';

// generated by Fragment Matcher plugin
import { introspectionResult } from '@allergan-data-labs/alle-graphql-types';

export interface WriteDataOptions<TData> {
  /**
   * Copied from Apollo-Cache
   * The data you will be writing to the store.
   * It also takes an optional id property.
   * The id is used to write a fragment to an existing object in the store.
   */
  data: TData;
  id?: string;
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

let client = new ApolloClient<NormalizedCacheObject>();
const AlleApolloClient = (
  config: PresetConfig,
  localCache?: {
    resolvers?: Resolvers | Resolvers[];
    initialCache?: WriteDataOptions<any>;
  }
): ApolloClient<NormalizedCacheObject> => {
  client = new ApolloClient({
    cache,
    ...(localCache?.resolvers ? { resolvers: localCache.resolvers } : {}),
    ...config,
  });

  if (localCache?.initialCache) {
    const data = localCache?.initialCache;
    client.cache.writeData(data);
    client.onResetStore(() => {
      return Promise.resolve()
        .then(() => client.cache.writeData(data))
        .catch((error) => {
          console.error('ApolloClient#resetStore', error);
        });
    });
  }

  return client;
};

// Create a client instance without affecting the global client
const ApolloClientFactory = (
  config: PresetConfig
): ApolloClient<NormalizedCacheObject> => {
  const localCache = new InMemoryCache({
    fragmentMatcher,
  });
  return new ApolloClient({
    cache: localCache,
    ...config,
  });
};

export { client, AlleApolloClient, ApolloClientFactory };
