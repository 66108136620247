import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import styled, { keyframes } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ALLERGAN_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';
import {
  above,
  screenSizes,
} from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';

// Components
import { AlleButton as Button } from '@allergan-data-labs/component-library/src/core/alleButton';
import { colors } from '@allergan-data-labs/component-library/src/core/colors';
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import { ConsentGiven } from '@allergan-data-labs/consumer-component-library/src/segment-tracking-plan-deprecated/generated';

const showAnimation = (x: string, y: string) => keyframes`
  from { transform: translate(${x}, ${y}); }
  to { transform: translate(${x}, 0); }
`;

const Container = styled.div`
  z-index: 999;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  padding: 15px;
  left: 0;
  position: fixed;
  transform: none;
  transition: height 0.5s ease-out;
  ${above.tablet`
    height: 180px;
    padding: 0 30px 0 30px;
  `}
  ${above.desktop`
    flex-direction: row;
    justify-content: flex-start;
    width: 819px;
    height: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  `}
  &.show {
    bottom: 0;
    animation: ${showAnimation('0', '200px')} linear 0.5s;
    ${above.desktop`
      animation: ${showAnimation('-50%', '100px')} linear 0.5s;
    `}
  }
  &.hide {
    bottom: -510px;
  }
  &.maximized {
    height: 470px;
    ${above.mobile`
      height: 550px;
    `}
    ${above.tablet`
      height: 350px;
    `}
    ${above.desktop`
      height: 400px;
    `}
  }
`;

const StyledLink = styled.a`
  vertical-align: baseline;
  color: ${colors.black};
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  && {
    width: 90%;
    min-width: 290px;
    max-width: 400px;
    margin-top: 15px;
    margin-bottom: 8px;
    ${above.mobile`
      min-width: 275px;
    `}
    ${above.desktop`
      margin-left: 50px;
      margin-bottom: 15px;
      &.maximized {
        margin-bottom: 295px;
      }
    `}
  }
`;

const StyledNormalText = styled(Typography)`
  text-align: left;
  margin: 0;
  font-size: 11px;
  ${above.mobile`
    margin: 0 25px;
    font-size: 13px;
  `}
  ${above.tablet`
    margin: 0;
  `}
`;

const StyledLinkButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  background-color: transparent;
  text-decoration: underline;
  & ${StyledNormalText} {
    font-size: 11px;
    margin: 0;
    ${above.mobile`
    font-size: 13px;
  `}
  }
`;

const Spacer = styled.div`
  min-height: 10px;
  ${above.mobile`
    min-height: 15px;
  `}
`;

interface CookieNotificationBannerProps {
  name: string;
  maxAge: number;
}

interface ContentBannerProps {
  showFullBanner: boolean;
  onShowMoreClick: () => void;
  onAcceptCookieClick: () => void;
}

interface ContentBannerButtonProps {
  showFullBanner: boolean;
  onClick: () => void;
}

const ShowMoreButton: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <StyledLinkButton onClick={onClick}>
      <StyledNormalText preset="body-M-regular">
        {showFullBanner ? 'Read Less' : 'Read More'}
      </StyledNormalText>
    </StyledLinkButton>
  );
};

const AcceptButton: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <StyledButton
      data-testid="accept-cookie"
      buttonType="contained"
      marginTop
      className={showFullBanner ? 'maximized' : ''}
      onClick={onClick}
    >
      ACCEPT
    </StyledButton>
  );
};

const DesktopSnippet: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      This website uses cookies and other technologies to personalize content
      and to show you more personalized ads (for example, Google Ads and
      Facebook) on this and other websites...&nbsp;
      <ShowMoreButton onClick={onClick} showFullBanner={showFullBanner} />
    </StyledNormalText>
  );
};

const MobileSnippet: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onAcceptCookieClick,
}) => {
  const isSmallMobile = useMediaQuery(`(max-width:320px)`);
  return (
    <>
      <StyledNormalText preset="body-M-regular">
        {isSmallMobile
          ? `This mobile app uses cookies and other technologies to personalize
          content and to show you more personalized ads...`
          : `This mobile app uses cookies and other technologies to personalize
        content and to show you more personalized ads (for example, Google Ads
        and Facebook) on this and other mobile apps and websites...`}
        &nbsp;
        <ShowMoreButton
          onClick={onShowMoreClick}
          showFullBanner={showFullBanner}
        />
      </StyledNormalText>
      <StyledNormalText preset="body-M-regular">
        <AcceptButton
          showFullBanner={showFullBanner}
          onClick={onAcceptCookieClick}
        />
      </StyledNormalText>
    </>
  );
};

const BannerFooter: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <>
      <StyledNormalText preset="body-M-regular">
        For more information, including information on the categories of third
        parties to whom we disclose your personal information, please refer to
        our global privacy policies, available at&nbsp;
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={ALLERGAN_URLS.californiaPrivacyPolicy}
        >
          www.allergan.com/privacy
        </StyledLink>
        . For additional options please check your browser’s settings.&nbsp;
        <ShowMoreButton onClick={onClick} showFullBanner={showFullBanner} />
      </StyledNormalText>
    </>
  );
};

const DesktopContent: React.FunctionComponent<ContentBannerButtonProps> = ({
  showFullBanner,
  onClick,
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      <StyledNormalText preset="body-M-regular">
        This website uses cookies and other technologies to personalize content
        and to show you more personalized ads (for example, Google Ads and
        Facebook) on this and other websites, as well as provide you with social
        media features on this website (such as, Facebook, Twitter, LinkedIn).
        By clicking “Accept” you understand that you are directing Allergan to
        disclose your personal information, including internet tracking data
        (like cookies and the IP address you used to access this website), to
        these third parties for the purposes stated above.
      </StyledNormalText>
      <Spacer />
      <StyledNormalText preset="body-M-regular">
        You can still use this website if you do not click “Accept,” but your
        experience may be different.
      </StyledNormalText>
      <Spacer />
      <StyledNormalText preset="body-M-regular">
        <BannerFooter showFullBanner={showFullBanner} onClick={onClick} />
      </StyledNormalText>
    </StyledNormalText>
  );
};

const MobileContent: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onAcceptCookieClick,
}) => {
  return (
    <>
      <StyledNormalText preset="body-M-regular">
        This mobile app uses cookies and other technologies to personalize
        content and to show you more personalized ads (for example, Google Ads
        and Facebook) on this and other mobile apps and websites, as well as
        provide you with social media features on this mobile app (such as,
        Facebook, Twitter, LinkedIn). By clicking “Accept” you understand that
        you are directing Allergan to disclose your personal information,
        including internet tracking data (like cookies and the IP address
        associated with this mobile device), to these third parties for the
        purposes stated above.
      </StyledNormalText>
      <Spacer />
      <StyledNormalText preset="body-M-regular">
        You can still use this mobile app if you do not click “Accept,” but your
        experience may be different.
      </StyledNormalText>
      <Spacer />
      <BannerFooter showFullBanner={showFullBanner} onClick={onShowMoreClick} />
      <StyledNormalText preset="body-M-regular">
        <AcceptButton
          showFullBanner={showFullBanner}
          onClick={onAcceptCookieClick}
        />
      </StyledNormalText>
    </>
  );
};

const DesktopBanner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onAcceptCookieClick,
}) => {
  return (
    <>
      {showFullBanner ? (
        <DesktopContent
          showFullBanner={showFullBanner}
          onClick={onShowMoreClick}
        />
      ) : (
        <DesktopSnippet
          showFullBanner={showFullBanner}
          onClick={onShowMoreClick}
        />
      )}
      <AcceptButton
        showFullBanner={showFullBanner}
        onClick={onAcceptCookieClick}
      />
    </>
  );
};

const MobileBanner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onAcceptCookieClick,
}) => {
  return showFullBanner ? (
    <MobileContent
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onAcceptCookieClick={onAcceptCookieClick}
    />
  ) : (
    <MobileSnippet
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onAcceptCookieClick={onAcceptCookieClick}
    />
  );
};

const Banner: React.FunctionComponent<ContentBannerProps> = ({
  showFullBanner,
  onShowMoreClick,
  onAcceptCookieClick,
}) => {
  const isTablet = useMediaQuery(`(min-width:${screenSizes.tablet}px)`);
  return isTablet ? (
    <DesktopBanner
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onAcceptCookieClick={onAcceptCookieClick}
    />
  ) : (
    <MobileBanner
      showFullBanner={showFullBanner}
      onShowMoreClick={onShowMoreClick}
      onAcceptCookieClick={onAcceptCookieClick}
    />
  );
};

const CookieNotificationBannerDEPRECATED: React.FunctionComponent<
  CookieNotificationBannerProps
> = ({ name, maxAge }): any => {
  const [cookies, setCookie] = useCookies([name]);
  const [showCookie, setShowCookie] = useState(!cookies[name]);
  const [showFullBanner, setShowFullBanner] = useState(false);

  const { trackDeprecated, trackConsumer } = useSegment();

  return (
    !cookies[name] && (
      <Container
        className={
          showCookie ? (showFullBanner ? 'show maximized' : 'show') : 'hide'
        }
        data-testid="cookie-notification-banner-container"
      >
        <Banner
          showFullBanner={showFullBanner}
          onShowMoreClick={() => {
            setShowFullBanner(!showFullBanner);
          }}
          onAcceptCookieClick={() => {
            setShowCookie(false);
            setTimeout(() => setCookie(name, true, { maxAge }), 500);
            const acceptClickedSegment: ConsentGiven = {
              activator: 'Accept button on cookie banner',
              explainer: 'Tracks cookie banner acceptance',
            };
            trackDeprecated()?.consentGiven(acceptClickedSegment);
            trackConsumer()?.consentApproved({
              consent_id: 'na',
              consent_type: ['cookies'],
              event: {
                action_source: 'consumer',
                activator: 'Cookie Banner',
                explainer: 'Tracking users interaction with cookie banner',
                invoked_by: 'consumer',
                event_type: 'privacy',
                user_type: 'consumer',
                user_status: 'logged out',
              },
            });
          }}
        />
      </Container>
    )
  );
};

export { CookieNotificationBannerDEPRECATED };
