/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
import * as Segment from './segment';

export interface Event {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping;
}
export interface Ids {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface AutocallAdded {
  /**
   * Indicates a type of autocall
   */
  autocall_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider;
}
export interface Event1 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing1 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office1 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping1 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address1 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing1;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office1;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping1;
}
export interface Ids1 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile1 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider1 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address1;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids1;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile1;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface AutocallApproved {
  /**
   * Indicates a type of approval
   */
  approval_type?: string;
  /**
   * Indicates a type of autocall
   */
  autocall_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event1;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider1;
}
export interface Event2 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing2 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office2 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping2 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address2 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing2;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office2;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping2;
}
export interface Ids2 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile2 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider2 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address2;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids2;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile2;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface AutocallRequested {
  /**
   * Indicates a type of autocall
   */
  autocall_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event2;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider2;
}
export interface Address3 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address3;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event3 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing3 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office3 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping3 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address4 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing3;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office3;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping3;
}
export interface Ids3 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile3 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider3 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address4;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids3;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile3;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface ConsentApproved {
  /**
   * Indicates a type of approval
   */
  approval_type?: string;
  /**
   * Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)
   */
  consent_id?: string;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event3;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider3;
}
export interface Address5 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate1 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined1 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name1 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer1 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address5;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate1;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined1;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name1;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event4 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing4 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office4 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping4 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address6 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing4;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office4;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping4;
}
export interface Ids4 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile4 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider4 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address6;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids4;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile4;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface ConsentDeclined {
  /**
   * Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)
   */
  consent_id?: string;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer1;
  /**
   * Indicating a type of decline
   */
  decline_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event4;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider4;
}
export interface Campaign {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address7 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate2 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined2 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name2 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer2 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address7;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate2;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined2;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name2;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Content {
  /**
   * Indicates the campaign related to this content
   */
  content_campaign?: string;
  /**
   * Uniquely identifies this articles associated with a campaign, by way of a campaign_id
   */
  content_campaign_id?: string;
  /**
   * Indicates the campaign name behind this content
   */
  content_campaign_name?: string;
  /**
   * Indicates the concern area of this content
   */
  content_concern_area?: string;
  /**
   * Indicates the headline related to this content
   */
  content_headline?: string;
  /**
   * Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
   */
  content_id?: string;
  /**
   * Indicates the journey theme of this content
   */
  content_journey?: string;
  /**
   * Indicates the product(s) related to this content
   */
  content_product?: string;
  /**
   * Indates the publish date of this content
   */
  content_publish_date?: string;
  /**
   * Indicates the slug of this content
   */
  content_slug?: string;
  /**
   * Indicates the tags related to this content
   */
  content_tag?: string;
  /**
   * Indicates the template used for this content
   */
  content_template?: string;
  /**
   * Indicates a type of content
   */
  content_type?: string;
  /**
   * Indicates the visual related to this content
   */
  content_visual?: string;
  /**
   * Indicates the orientation of this content
   */
  orientation?: string;
}
export interface Event5 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface ContentDownloaded {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer2;
  /**
   * Encapsulates data regarding a piece of content
   */
  content?: Content;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event5;
  /**
   * Indicating the name of an Allē tactic used to drive a given outcome
   */
  tactic_summary?: string;
  /**
   * Indicating a type of view
   */
  view_type?: string;
}
export interface Event6 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
}
export interface Billing5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address8 {
  /**
   * Billing address, if known
   */
  billing?: Billing5;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office5;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping5;
}
export interface Ids5 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider5 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address8;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids5;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ContentScrolled {
  /**
   * TO DO
   */
  content?: Record<string, any>;
  /**
   * NEEDS DESC
   */
  content_type?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event6;
  /**
   * NEEDS DESC
   */
  expiresAt?: string;
  /**
   * NEEDS DESC
   */
  fbclid?: string;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider5;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User;
}
export interface Campaign1 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address9 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate3 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined3 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name3 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer3 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address9;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate3;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined3;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name3;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Content1 {
  /**
   * Indicates the campaign related to this content
   */
  content_campaign?: string;
  /**
   * Uniquely identifies this articles associated with a campaign, by way of a campaign_id
   */
  content_campaign_id?: string;
  /**
   * Indicates the campaign name behind this content
   */
  content_campaign_name?: string;
  /**
   * Indicates the concern area of this content
   */
  content_concern_area?: string;
  /**
   * Indicates the headline related to this content
   */
  content_headline?: string;
  /**
   * Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
   */
  content_id?: string;
  /**
   * Indicates the journey theme of this content
   */
  content_journey?: string;
  /**
   * Indicates the product(s) related to this content
   */
  content_product?: string;
  /**
   * Indates the publish date of this content
   */
  content_publish_date?: string;
  /**
   * Indicates the slug of this content
   */
  content_slug?: string;
  /**
   * Indicates the tags related to this content
   */
  content_tag?: string;
  /**
   * Indicates the template used for this content
   */
  content_template?: string;
  /**
   * Indicates a type of content
   */
  content_type?: string;
  /**
   * Indicates the visual related to this content
   */
  content_visual?: string;
  /**
   * Indicates the orientation of this content
   */
  orientation?: string;
}
export interface Event7 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing6 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office6 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping6 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address10 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing6;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office6;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping6;
}
export interface Ids6 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile5 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider6 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address10;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids6;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile5;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface ContentViewed {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign1;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer3;
  /**
   * Encapsulates data regarding a piece of content
   */
  content?: Content1;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event7;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider6;
  /**
   * Indicating the name of an Allē tactic used to drive a given outcome
   */
  tactic_summary?: string;
  /**
   * Indicating a type of view
   */
  view_type?: string;
}
export interface Address11 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate4 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined4 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name4 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer4 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address11;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate4;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined4;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name4;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Content2 {
  /**
   * Indicates the campaign related to this content
   */
  content_campaign?: string;
  /**
   * Uniquely identifies this articles associated with a campaign, by way of a campaign_id
   */
  content_campaign_id?: string;
  /**
   * Indicates the campaign name behind this content
   */
  content_campaign_name?: string;
  /**
   * Indicates the concern area of this content
   */
  content_concern_area?: string;
  /**
   * Indicates the headline related to this content
   */
  content_headline?: string;
  /**
   * Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
   */
  content_id?: string;
  /**
   * Indicates the journey theme of this content
   */
  content_journey?: string;
  /**
   * Indicates the product(s) related to this content
   */
  content_product?: string;
  /**
   * Indates the publish date of this content
   */
  content_publish_date?: string;
  /**
   * Indicates the slug of this content
   */
  content_slug?: string;
  /**
   * Indicates the tags related to this content
   */
  content_tag?: string;
  /**
   * Indicates the template used for this content
   */
  content_template?: string;
  /**
   * Indicates a type of content
   */
  content_type?: string;
  /**
   * Indicates the visual related to this content
   */
  content_visual?: string;
  /**
   * Indicates the orientation of this content
   */
  orientation?: string;
}
export interface Event8 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface ElementEngaged {
  /**
   * Indicates what a user did in order to fire an event
   */
  action?: string;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer4;
  /**
   * Encapsulates data regarding a piece of content
   */
  content?: Content2;
  /**
   * Indicating a type of user interface element
   */
  element_type?: string;
  /**
   * Indicating a type of engagement
   */
  engagement_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event8;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
}
export interface Campaign2 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address12 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate5 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined5 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name5 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer5 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address12;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate5;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined5;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name5;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event9 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing7 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office7 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping7 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address13 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing7;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office7;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping7;
}
export interface Ids7 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile6 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider7 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address13;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids7;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile6;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface EnrollmentCompleted {
  /**
   * Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV
   */
  brands?: any[];
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign2;
  /**
   * Indicates a type of completion
   */
  completion_type?: string;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer5;
  /**
   * Indicating a type of enrollment
   */
  enrollment_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event9;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider7;
  /**
   * Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
   */
  zip_code?: string;
}
export interface Campaign3 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address14 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate6 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined6 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name6 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer6 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address14;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate6;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined6;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name6;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event10 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface EnrollmentStarted {
  /**
   * Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV
   */
  brands?: any[];
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign3;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer6;
  /**
   * Indicating a type of enrollment
   */
  enrollment_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event10;
  /**
   * Indicating a type of starting action
   */
  start_type?: string;
  /**
   * Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
   */
  zip_code?: string;
}
export interface Address15 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate7 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined7 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name7 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer7 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address15;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate7;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined7;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name7;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event11 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface EnrollmentUpdated {
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer7;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event11;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
}
export interface Address16 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate8 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined8 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name8 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer8 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address16;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate8;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined8;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name8;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event12 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing8 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office8 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping8 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address17 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing8;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office8;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping8;
}
export interface Ids8 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile7 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider8 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address17;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids8;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile7;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface ErrorTriggered {
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer8;
  /**
   * Indicates a developer-friendly code for error diagnosis
   */
  error_code?: string;
  /**
   * Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined
   */
  error_reason?: string;
  /**
   * Indicates a type of error in a human-readable way
   */
  error_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event12;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider8;
  /**
   * Indicates a string of text relevant for this event
   */
  text?: string;
  /**
   * Indicating a type of trigger
   */
  trigger_type?: string;
}
export interface Address18 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate9 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined9 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name9 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer9 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address18;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate9;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined9;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name9;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event13 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface HelpRequested {
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer9;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event13;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of help
   */
  help_type?: string;
  /**
   * Indicating a type of lead request
   */
  request_type?: string;
}
export interface Address19 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate10 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined10 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name10 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer10 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address19;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate10;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined10;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name10;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event14 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface InformationCollected {
  /**
   * Indicates a type of collection
   */
  collection_type?: string;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer10;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event14;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of information
   */
  information_type?: string;
  offer?: Offer;
  /**
   * Indicating a description of an offer which is correlated with this event firing
   */
  offer_slug?: string;
  /**
   * Indicating a type of registration
   */
  registration_type?: string;
  /**
   * Indicates the step number for a form filling activity
   */
  step?: number;
}
export interface Event15 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface InformationDownloaded {
  /**
   * Indicating a type of download
   */
  download_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event15;
  /**
   * Indicating a type of information
   */
  information_type?: string;
}
export interface Event16 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing9 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office9 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping9 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address20 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing9;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office9;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping9;
}
export interface Ids9 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile8 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider9 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address20;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids9;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile8;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadCopied {
  /**
   * Indicating a type of duplication
   */
  copy_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event16;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider9;
  /**
   * Indicating which value was copied to the device's clipboard
   */
  value_copied?: string;
}
export interface Event17 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer1 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing10 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office10 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping10 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address21 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing10;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office10;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping10;
}
export interface Ids10 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile9 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider10 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address21;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids10;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile9;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadGenerated {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event17;
  /**
   * Indicating a type of reason why something came about
   */
  generation_type?: string;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  offer?: Offer1;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider10;
}
export interface Event18 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing11 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office11 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping11 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address22 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing11;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office11;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping11;
}
export interface Ids11 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile10 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider11 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address22;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids11;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile10;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadStarted {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event18;
  /**
   * This indicates the referring URL
   */
  event_source_url?: string;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider11;
  /**
   * Indicating a type of lead request
   */
  request_type?: string;
  /**
   * Indicating a type of starting action
   */
  start_type?: string;
}
export interface Event19 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing12 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office12 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping12 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address23 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing12;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office12;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping12;
}
export interface Ids12 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile11 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider12 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address23;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids12;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile11;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadUnmasked {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event19;
  /**
   * Indicating the unique id for a given lead
   */
  lead_id?: string;
  /**
   * Indicating the source of a given lead
   */
  lead_source?: string;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider12;
  /**
   * Indicating the type of unmask action which took place
   */
  unmask_type?: string;
}
export interface Event20 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing13 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office13 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping13 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address24 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing13;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office13;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping13;
}
export interface Ids13 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile12 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider13 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address24;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids13;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile12;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadUpdated {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event20;
  /**
   * Indicating the unique id for a given lead
   */
  lead_id?: string;
  /**
   * Indicating the source of a given lead
   */
  lead_source?: string;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider13;
  /**
   * Indicating a type of update
   */
  update_type?: string;
}
export interface Event21 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing14 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office14 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping14 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address25 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing14;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office14;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping14;
}
export interface Ids14 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile13 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider14 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address25;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids14;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile13;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadsConverted {
  /**
   * Indicating a type of conversion
   */
  conversion_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event21;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Indicating which offers (promotion_id) were used as part of a transactionan array of promotion_id values
   */
  offers_redeemed?: any[];
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider14;
}
export interface Event22 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing15 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office15 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping15 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address26 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing15;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office15;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping15;
}
export interface Ids15 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile14 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider15 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address26;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids15;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile14;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface LeadsDownloaded {
  /**
   * Indicating a type of download
   */
  download_type?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event22;
  /**
   * Indicating the type of a given lead
   */
  lead_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider15;
}
export interface Campaign4 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Event23 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer2 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface LoginStarted {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign4;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event23;
  /**
   * This indicates the referring URL
   */
  event_source_url?: string;
  /**
   * Indicating a type of login
   */
  login_type?: string;
  offer?: Offer2;
  /**
   * Indicating a type of starting action
   */
  start_type?: string;
  /**
   * Indicating the Uniform Resource Locator for a given internet location
   */
  url?: string;
}
export interface Campaign5 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address27 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate11 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined11 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name11 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer11 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address27;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate11;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined11;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name11;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event24 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer3 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface LoginSucceeded {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign5;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer11;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event24;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of login
   */
  login_type?: string;
  offer?: Offer3;
  /**
   * Indicating a description of an offer which is correlated with this event firing
   */
  offer_slug?: string;
  /**
   * Indicating a type of success
   */
  success_type?: string;
}
export interface Event25 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface LogoutSucceeded {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event25;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of logout
   */
  logout_type?: string;
  /**
   * Indicating a type of success
   */
  success_type?: string;
}
export interface Campaign6 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Event26 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer4 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface OfferAdded {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign6;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event26;
  offer?: Offer4;
}
export interface Campaign7 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address28 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate12 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined12 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name12 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer12 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address28;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate12;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined12;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name12;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event27 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer5 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing16 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office16 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping16 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address29 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing16;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office16;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping16;
}
export interface Ids16 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile15 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider16 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address29;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids16;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile15;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferClaimed {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign7;
  /**
   * Indicates a type of claim
   */
  claim_type?: string;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer12;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event27;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating whether or not a consumer was newly registered
   */
  new_registration?: boolean;
  offer?: Offer5;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider16;
}
export interface Campaign8 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Event28 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer6 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing17 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office17 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping17 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address30 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing17;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office17;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping17;
}
export interface Ids17 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile16 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider17 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address30;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids17;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile16;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferFailed {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign8;
  /**
   * Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined
   */
  error_reason?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event28;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of failure
   */
  fail_type?: string;
  /**
   * Indicating whether or not a consumer was newly registered
   */
  new_registration?: boolean;
  offer?: Offer6;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider17;
}
export interface Campaign9 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address31 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate13 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined13 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name13 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer13 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address31;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate13;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined13;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name13;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event29 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer7 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing18 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office18 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping18 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address32 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing18;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office18;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping18;
}
export interface Ids18 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile17 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider18 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address32;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids18;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile17;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferPresented {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign9;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer13;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event29;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  offer?: Offer7;
  /**
   * Indicating a type of presentation
   */
  presentation_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider18;
  /**
   * Indicating the unique identifier for a given template
   */
  template_id?: string;
}
export interface Campaign10 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address33 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate14 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined14 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name14 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer14 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address33;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate14;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined14;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name14;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event30 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer8 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing19 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office19 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping19 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address34 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing19;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office19;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping19;
}
export interface Ids19 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile18 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider19 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address34;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids19;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile18;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferRedeemed {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign10;
  /**
   * Indicates a unique identifier for a given consumer checkout at a provider's office -
   */
  checkout_id?: string;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer14;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event30;
  offer?: Offer8;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider19;
  /**
   * Indicating a type of redemption
   */
  redemption_type?: string;
}
export interface Event31 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer9 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface OfferRemoved {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event31;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  offer?: Offer9;
  /**
   * Indicating a type of removal
   */
  removal_type?: string;
}
export interface Campaign11 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address35 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate15 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined15 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name15 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer15 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address35;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate15;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined15;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name15;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event32 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer10 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface OfferRequested {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign11;
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer15;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event32;
  offer?: Offer10;
  /**
   * Indicating a type of lead request
   */
  request_type?: string;
  /**
   * Indicates the step number for a form filling activity
   */
  step?: number;
}
export interface Address36 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate16 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined16 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name16 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer16 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address36;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate16;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined16;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name16;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event33 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer11 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing20 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office20 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping20 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address37 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing20;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office20;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping20;
}
export interface Ids20 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile19 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider20 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address37;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids20;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile19;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferShared {
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer16;
  /**
   * Indicates the email address of a user who will be recipient of the share action
   */
  email_shared?: string;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event33;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  offer?: Offer11;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider20;
  /**
   * Indicating a type of share
   */
  share_type?: string;
}
export interface Address38 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate17 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined17 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name17 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer17 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address38;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate17;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined17;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name17;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event34 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer12 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing21 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office21 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping21 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address39 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing21;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office21;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping21;
}
export interface Ids21 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile20 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider21 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address39;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids21;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile20;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferSubmitted {
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer17;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event34;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  offer?: Offer12;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider21;
  /**
   * Indicating a type of submission
   */
  submission_type?: string;
}
export interface Address40 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate18 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined18 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name18 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer18 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address40;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate18;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined18;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name18;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event35 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer13 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing22 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office22 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping22 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address41 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing22;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office22;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping22;
}
export interface Ids22 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile21 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider22 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address41;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids22;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile21;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface OfferSuppressed {
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer18;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event35;
  offer?: Offer13;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider22;
  /**
   * Indicates a human-readable explanation for why a given event happened
   */
  reason?: string;
  /**
   * Indicating a type of suppressing action
   */
  suppression_type?: string;
}
export interface Content3 {
  /**
   * Indicates the campaign related to this content
   */
  content_campaign?: string;
  /**
   * Uniquely identifies this articles associated with a campaign, by way of a campaign_id
   */
  content_campaign_id?: string;
  /**
   * Indicates the campaign name behind this content
   */
  content_campaign_name?: string;
  /**
   * Indicates the concern area of this content
   */
  content_concern_area?: string;
  /**
   * Indicates the headline related to this content
   */
  content_headline?: string;
  /**
   * Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
   */
  content_id?: string;
  /**
   * Indicates the journey theme of this content
   */
  content_journey?: string;
  /**
   * Indicates the product(s) related to this content
   */
  content_product?: string;
  /**
   * Indates the publish date of this content
   */
  content_publish_date?: string;
  /**
   * Indicates the slug of this content
   */
  content_slug?: string;
  /**
   * Indicates the tags related to this content
   */
  content_tag?: string;
  /**
   * Indicates the template used for this content
   */
  content_template?: string;
  /**
   * Indicates a type of content
   */
  content_type?: string;
  /**
   * Indicates the visual related to this content
   */
  content_visual?: string;
  /**
   * Indicates the orientation of this content
   */
  orientation?: string;
}
export interface Event36 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing23 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office23 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping23 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address42 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing23;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office23;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping23;
}
export interface Ids23 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile22 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider23 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address42;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids23;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile22;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface PageScrolled {
  /**
   * Encapsulates data regarding a piece of content
   */
  content?: Content3;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event36;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of page
   */
  page_type?: string;
  /**
   * Indicating the % of the page/screen which was scrolled fires at 25/50/75/90 percent
   */
  percent_scrolled?: number;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider23;
  /**
   * Indicating a type of scroll
   */
  scroll_type?: string;
  /**
   * Indicating the last part of a URL address that serves as a unique identifier of the page
   */
  slug?: string;
}
export interface Event37 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface PasswordForgotten {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event37;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a reason why something was forgotten
   */
  forgetfulness_type?: string;
  /**
   * Indicating a type of password
   */
  password_type?: string;
}
export interface Event38 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface PasswordUpdated {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event38;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of password
   */
  password_type?: string;
  /**
   * Indicating a type of update
   */
  update_type?: string;
}
export interface Event39 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface ProviderContacted {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event39;
}
export interface Event40 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Query {
  /**
   * Freeform object used to qualify searches. Use name value pairs as specified by business needs
   */
  filter_data?: Record<string, any>;
  /**
   * Indicates if the current location of the device was used in this query
   */
  is_current_location?: boolean;
  /**
   * Indicates the radius from the zip code related to this query
   */
  radius?: number;
  /**
   * Indicates the strings used to narrow down this query
   */
  search_text?: string;
  /**
   * Indicates the type of sorting used as part of this query, e.g. 'ascending'
   */
  sort_type?: string;
  /**
   * Indicates the zip code related to this query
   */
  zip_code?: string;
}
export interface ProviderSearched {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event40;
  /**
   * Indicating a type of provider
   */
  provider_type?: string;
  /**
   * an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
   */
  query?: Query;
  /**
   * Indicating a type of search
   */
  search_type?: string;
  /**
   * Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
   */
  zip_code?: string;
}
export interface Address43 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate19 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined19 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name19 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer19 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address43;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate19;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined19;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name19;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event41 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing24 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office24 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping24 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address44 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing24;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office24;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping24;
}
export interface Ids24 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile23 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider24 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address44;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids24;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile23;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface ProviderViewed {
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer19;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event41;
  /**
   * This indicates the referring URL
   */
  event_source_url?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider24;
  provider_count?: number;
  /**
   * Indicating a type of provider
   */
  provider_type?: string;
  /**
   * Indicating the pagination number of the search result experience
   */
  results_page?: string;
  /**
   * Indicating a type of view
   */
  view_type?: string;
}
export interface Campaign12 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Address45 {
  /**
   * Indicates the city this consumer, sits in @address object
   */
  city?: string;
  /**
   * Indicates the country this consumer, sits in @address object
   */
  country?: string;
  /**
   * Indicates the county this consumer, sits in @address object
   */
  county?: string;
  /**
   * Indicates the state of this consumer, sits in @address object
   */
  state?: string;
  /**
   * Indicates the street this consumer, sits in @address object
   */
  street?: string;
  /**
   * Indicates the zip code of this consumer, sits in @address object
   */
  zip_code?: string;
}
export interface Birthdate20 {
  /**
   * Indicates the birthdate day of this consumer, sits in @birthdate object
   */
  day?: number;
  /**
   * Indicates the birthdate month of this consumer, sits in @birthdate object
   */
  month?: number;
  /**
   * Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
   */
  year?: number;
}
export interface Joined20 {
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  alle?: string;
  /**
   * Indicates the alle join date of this consumer, sits in @joined object
   */
  bd?: string;
}
export interface Name20 {
  /**
   * the consumers first (given) name
   */
  first?: string;
  /**
   * the consumers last (family) name
   */
  last?: string;
  /**
   * the consumers middle name
   */
  middle?: string;
}
export interface Consumer20 {
  /**
   * Indicates the email address of the consumer
   */
  ''?: string;
  /**
   * Encapsulates data regarding the consumer's address
   */
  address?: Address45;
  /**
   * Uniquely identifies this consumer, by way of an alle ID
   */
  alle_id?: string;
  /**
   * Encapsulates data regarding the consumer's birthdate
   */
  birthdate?: Birthdate20;
  /**
   * indicates the email address of the user.
   */
  email?: string;
  /**
   * Encapsulates data regarding the dates in which the consumer joined our programs
   */
  joined?: Joined20;
  /**
   * encapsulates data regarding the consumer's name
   */
  name?: Name20;
  /**
   * Indicates the phone number of this consumer
   */
  phone?: string;
}
export interface Event42 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer14 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing25 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office25 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping25 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address46 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing25;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office25;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping25;
}
export interface Ids25 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile24 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider25 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address46;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids25;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile24;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface RegistrationCompleted {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign12;
  /**
   * Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
   */
  consent_type?: any[];
  /**
   * Encapsulates data regarding an Allē consumer
   */
  consumer?: Consumer20;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event42;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  offer?: Offer14;
  /**
   * Indicating a description of an offer which is correlated with this event firing
   */
  offer_slug?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider25;
  /**
   * Indicating a type of registration
   */
  registration_type?: string;
}
export interface Campaign13 {
  /**
   * Uniquely identifies this instance of a campaign, as generated by the Grow backend
   */
  campaign_id?: string;
  /**
   * Indicates the source of truth for generating the campaign_id
   */
  campaign_id_source?: string;
  /**
   * Indicates the name of this campaign
   */
  campaign_name?: string;
  /**
   * Uniquely identifies this campaign template, as generated by the Grow backend
   */
  campaign_template_id?: string;
  /**
   * Indicates the type of this campaign
   */
  campaign_type?: string;
}
export interface Event43 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Offer15 {
  /**
   * Indicates the activation type of this offer
   */
  offer_activation?: string;
  /**
   * IIndicates the institution which financially backed the offer
   */
  offer_benefactor?: string;
  /**
   * Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
   */
  offer_brands?: any[];
  /**
   * Indicates whether or not this offer has been claimed
   */
  offer_claimed?: string;
  /**
   * Indicates the expiration of this offer
   */
  offer_expiration?: string;
  /**
   * Indicates the display name of this offer
   */
  offer_name?: string;
  /**
   * Indicates the internal name of this offer, which may differ from the display name used by offer_name
   */
  offer_name_internal?: string;
  /**
   * Indicates the slug of this offer
   */
  offer_slug?: string;
  /**
   * Indicates a type of offer
   */
  offer_type?: string;
  /**
   * Indicates the value of this offer in USD
   */
  offer_value?: string;
  /**
   * Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
   */
  promotion_id?: string;
}
export interface Billing26 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office26 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping26 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address47 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing26;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office26;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping26;
}
export interface Ids26 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile25 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider26 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address47;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids26;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile25;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface RegistrationStarted {
  /**
   * an object containing descriptive properties of a campaign
   */
  campaign?: Campaign13;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event43;
  offer?: Offer15;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider26;
  /**
   * Indicating a type of registration
   */
  registration_type?: string;
  /**
   * Indicating a type of starting action
   */
  start_type?: string;
}
export interface Event44 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Query1 {
  /**
   * Freeform object used to qualify searches. Use name value pairs as specified by business needs
   */
  filter_data?: Record<string, any>;
  /**
   * Indicates if the current location of the device was used in this query
   */
  is_current_location?: boolean;
  /**
   * Indicates the radius from the zip code related to this query
   */
  radius?: number;
  /**
   * Indicates the strings used to narrow down this query
   */
  search_text?: string;
  /**
   * Indicates the type of sorting used as part of this query, e.g. 'ascending'
   */
  sort_type?: string;
  /**
   * Indicates the zip code related to this query
   */
  zip_code?: string;
}
export interface ResultsFiltered {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event44;
  /**
   * Indicating a type of filter
   */
  filter_type?: string;
  /**
   * an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
   */
  query?: Query1;
  results_count?: number;
  /**
   * Indicating a type of search results
   */
  results_type?: string;
}
export interface Event45 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing27 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office27 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping27 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address48 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing27;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office27;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping27;
}
export interface Ids27 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile26 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider27 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address48;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids27;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile26;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface Query2 {
  /**
   * Freeform object used to qualify searches. Use name value pairs as specified by business needs
   */
  filter_data?: Record<string, any>;
  /**
   * Indicates if the current location of the device was used in this query
   */
  is_current_location?: boolean;
  /**
   * Indicates the radius from the zip code related to this query
   */
  radius?: number;
  /**
   * Indicates the strings used to narrow down this query
   */
  search_text?: string;
  /**
   * Indicates the type of sorting used as part of this query, e.g. 'ascending'
   */
  sort_type?: string;
  /**
   * Indicates the zip code related to this query
   */
  zip_code?: string;
}
export interface ResultsViewed {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event45;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider27;
  /**
   * an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
   */
  query?: Query2;
  results_count?: number;
  /**
   * Indicating a type of search results
   */
  results_type?: string;
  /**
   * Indicating a type of view
   */
  view_type?: string;
}
export interface Content4 {
  /**
   * Indicates the campaign related to this content
   */
  content_campaign?: string;
  /**
   * Uniquely identifies this articles associated with a campaign, by way of a campaign_id
   */
  content_campaign_id?: string;
  /**
   * Indicates the campaign name behind this content
   */
  content_campaign_name?: string;
  /**
   * Indicates the concern area of this content
   */
  content_concern_area?: string;
  /**
   * Indicates the headline related to this content
   */
  content_headline?: string;
  /**
   * Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
   */
  content_id?: string;
  /**
   * Indicates the journey theme of this content
   */
  content_journey?: string;
  /**
   * Indicates the product(s) related to this content
   */
  content_product?: string;
  /**
   * Indates the publish date of this content
   */
  content_publish_date?: string;
  /**
   * Indicates the slug of this content
   */
  content_slug?: string;
  /**
   * Indicates the tags related to this content
   */
  content_tag?: string;
  /**
   * Indicates the template used for this content
   */
  content_template?: string;
  /**
   * Indicates a type of content
   */
  content_type?: string;
  /**
   * Indicates the visual related to this content
   */
  content_visual?: string;
  /**
   * Indicates the orientation of this content
   */
  orientation?: string;
}
export interface Event46 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing28 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office28 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping28 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address49 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing28;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office28;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping28;
}
export interface Ids28 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile27 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider28 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address49;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids28;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile27;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface SiteNavigated {
  /**
   * Indicates the position of a given content card relative to other content cards
   */
  card_position?: string;
  /**
   * Encapsulates data regarding a piece of content
   */
  content?: Content4;
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event46;
  /**
   * This indicates the referring URL
   */
  event_source_url?: string;
  /**
   * Indicating some form of an expiration - no idea where this is generated
   */
  expiresAt?: number;
  /**
   * Indicating a type of navigation
   */
  navigation_type?: string;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider28;
  /**
   * Indicating the name of a content section
   */
  section?: string;
  /**
   * Indicating the position count of a given piece of content within a section
   */
  section_position?: number;
  /**
   * Indicating a type of site
   */
  site_type?: string;
  /**
   * Indicates a string of text relevant for this event
   */
  text?: string;
  /**
   * Indicating the Uniform Resource Locator for a given internet location
   */
  url?: string;
}
export interface Event47 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface Billing29 {
  /**
   * Indicates the billing city of this provider, sits in @address.billing object
   */
  city?: string;
  /**
   * Indicates the billing country of this provider, sits in @address.billing object
   */
  country?: string;
  /**
   * Indicates the billing county of this provider, sits in @address.billing object
   */
  county?: string;
  /**
   * Indicates the billing street of this provider, sits in @address.billing object
   */
  street?: string;
  /**
   * Indicates the billing zip code of this provider, sits in @address.billing object
   */
  zip_code?: string;
}
export interface Office29 {
  /**
   * Indicates the office city this provider, sits in @address.office object
   */
  city?: string;
  /**
   * Indicates the office country this provider, sits in @address.office object
   */
  country?: string;
  /**
   * Indicates the office county this provider, sits in @address.office object
   */
  county?: string;
  /**
   * Indicates the office street of this provider, sits in @address.office object
   */
  street?: string;
  /**
   * Indicates the office zip code this provider, sits in @address.office object
   */
  zip_code?: string;
}
export interface Shipping29 {
  /**
   * Indicates the shipping city of this provider, sits in @address.shipping object
   */
  city?: string;
  /**
   * Indicates the shipping country of this provider, sits in @address.shipping object
   */
  country?: string;
  /**
   * Indicates the shipping county this provider, sits in @address.shipping object
   */
  county?: string;
  /**
   * Indicates the shipping street of this provider, sits in @address.shipping object
   */
  street?: string;
  /**
   * Indicates the shipping zip code of this provider, sits in @address.shipping object
   */
  zip_code?: string;
}
export interface Address50 {
  /**
   * Encapsulates data regarding the provider's billing address
   */
  billing?: Billing29;
  /**
   * Encapsulates data regarding the provider's office address
   */
  office?: Office29;
  /**
   * Encapsulates data regarding the provider's shipping address
   */
  shipping?: Shipping29;
}
export interface Ids29 {
  /**
   * Uniquely identifies this business, by way of a business_id provided by A4B
   */
  business_id?: string;
  /**
   * Uniquely identifies this location, by way of a location_id provided by A4B
   */
  location_id?: string;
  /**
   * Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
   */
  provider_id?: string;
}
export interface Profile28 {
  /**
   * Indicates the booking url of this business location, sits in @profile object
   */
  booking_url?: string;
  /**
   * Indicates the channel of this business location, sits in @profile object
   */
  channel?: string;
  /**
   * Indicates the consultation cost of this business location, sits in @profile object
   */
  consultation_cost?: string;
  /**
   * Indicates if consultations are enabled for this business location, sits in @profile object
   */
  consultation_enabled?: boolean;
  /**
   * Indicates the contact email address of this business location, sits in @profile object
   */
  contact_email_address?: string;
  /**
   * Indicates if contact information was added to this profile, sits in @profile object
   */
  contact_info_added?: boolean;
  /**
   * Indicates the instagram handle of this business location, sits in @profile object
   */
  contact_instagram_handle?: string;
  /**
   * Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
   */
  hide_consultation_form?: boolean;
  /**
   * Indicates if hours of operation were updated for this business location, sits in @profile object
   */
  hours_added?: boolean;
  /**
   * Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * Indicates the number of locations which were updated, sits in @profile object
   */
  location_count?: number;
  /**
   * Indicates which locations were updated, sits in @profile object
   */
  locations_updated?: any[];
  /**
   * Indicates the number of days closed at this business location, sits in @profile object
   */
  num_days_closed?: number;
  /**
   * Indicates the number of days open at this business location, sits in @profile object
   */
  num_days_open?: number;
  /**
   * Indicates the ___ of this business location, sits in @profile object
   */
  num_days_set?: number;
  /**
   * Indicates the number of gallery photos shown at this business location, sits in @profile object
   */
  num_gallery_photos?: number;
  /**
   * Total number of products being shown in the profile
   */
  num_products?: number;
  /**
   * Indicates the phone number of this business location, sits in @profile object
   */
  phone_number?: string;
  /**
   * Indicating whether or not photos were added
   */
  photos_added?: boolean;
  /**
   * Indicating whether or not a practitioner was added
   */
  practitioner_added?: boolean;
  /**
   * Indicates the level of completeness of this profile, sits in @profile object
   */
  profile_completeness?: number;
  /**
   * Indicating a type of publish
   */
  publish_type?: string;
  /**
   * Indicates the email where lead requests are sent for this profile, sits in @profile object
   */
  send_requests_to_email?: string;
  /**
   * Indicates the success of a publish event, sits in @profile object
   */
  success?: boolean;
  /**
   * Indicates if treatments were added to this profile, sits in @profile object
   */
  treatments_added?: boolean;
}
export interface Provider29 {
  /**
   * Encapsulates data regarding the provider's addresses
   */
  address?: Address50;
  /**
   * Indicates a freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * Indicates the email address of this provider
   */
  email?: string;
  /**
   * Encapsulates data regarding the ids used to identify this provider
   */
  ids?: Ids29;
  /**
   * freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
   */
  media_urls?: Record<string, any>;
  /**
   * Indicates the name of this provider
   */
  name?: string;
  /**
   * Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
   */
  phone?: string;
  /**
   * Indicates the phone number extension of this provider
   */
  phone_extension?: string;
  /**
   * Encapsulates data regarding the provider profile related to a business location
   */
  profile?: Profile28;
  /**
   * Indicates the website url of this provider
   */
  url?: string;
}
export interface TransitionViewed {
  consent_type?: any[];
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event47;
  /**
   * Encapsulates data regarding general information about a provider
   */
  provider?: Provider29;
  tactic_summary?: string;
  transition_type?: string;
  view_type?: string;
}
export interface Event48 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface VideoEnded {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event48;
  title?: string;
  url?: string;
  video_type?: string;
}
export interface Event49 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface VideoPaused {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event49;
  title?: string;
  url?: string;
  video_type?: string;
}
export interface Event50 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface VideoScrolled {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event50;
  title?: string;
  url?: string;
  video_type?: string;
}
export interface Event51 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
   */
  activator?: string;
  /**
   * Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
   */
  event_step?: string;
  /**
   * Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
   */
  event_type?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
   */
  invoked_by?: string;
  /**
   * Indicates whether or not this user is logged in or not. logged|not logged
   */
  user_status?: string;
  /**
   * Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
   */
  user_type?: string;
}
export interface VideoStarted {
  /**
   * Encapsulates data regarding general information about this event
   */
  event?: Event51;
  expiresAt?: number;
  start_type?: string;
  title?: string;
  url?: string;
  video_type?: string;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[]
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef Event
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address
 * @property {Billing} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider
 * @property {Address} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef AutocallAdded
 * @property {string} [autocall_type] - Indicates a type of autocall
 * @property {Event} [event] - Encapsulates data regarding general information about this event
 * @property {Provider} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Event1
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing1
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office1
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping1
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address1
 * @property {Billing1} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office1} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping1} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids1
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile1
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider1
 * @property {Address1} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids1} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile1} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef AutocallApproved
 * @property {string} [approval_type] - Indicates a type of approval
 * @property {string} [autocall_type] - Indicates a type of autocall
 * @property {Event1} [event] - Encapsulates data regarding general information about this event
 * @property {Provider1} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Event2
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing2
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office2
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping2
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address2
 * @property {Billing2} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office2} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping2} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids2
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile2
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider2
 * @property {Address2} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids2} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile2} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef AutocallRequested
 * @property {string} [autocall_type] - Indicates a type of autocall
 * @property {Event2} [event] - Encapsulates data regarding general information about this event
 * @property {Provider2} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Address3
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address3} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event3
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing3
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office3
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping3
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address4
 * @property {Billing3} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office3} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping3} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids3
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile3
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider3
 * @property {Address4} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids3} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile3} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef ConsentApproved
 * @property {string} [approval_type] - Indicates a type of approval
 * @property {string} [consent_id] - Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event3} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Provider3} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Address5
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate1
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined1
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name1
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer1
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address5} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate1} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined1} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name1} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event4
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing4
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office4
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping4
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address6
 * @property {Billing4} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office4} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping4} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids4
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile4
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider4
 * @property {Address6} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids4} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile4} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef ConsentDeclined
 * @property {string} [consent_id] - Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer1} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {string} [decline_type] - Indicating a type of decline
 * @property {Event4} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Provider4} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Campaign
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address7
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate2
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined2
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name2
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer2
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address7} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate2} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined2} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name2} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Content
 * @property {string} [content_campaign] - Indicates the campaign related to this content
 * @property {string} [content_campaign_id] - Uniquely identifies this articles associated with a campaign, by way of a campaign_id
 * @property {string} [content_campaign_name] - Indicates the campaign name behind this content
 * @property {string} [content_concern_area] - Indicates the concern area of this content
 * @property {string} [content_headline] - Indicates the headline related to this content
 * @property {string} [content_id] - Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
 * @property {string} [content_journey] - Indicates the journey theme of this content
 * @property {string} [content_product] - Indicates the product(s) related to this content
 * @property {string} [content_publish_date] - Indates the publish date of this content
 * @property {string} [content_slug] - Indicates the slug of this content
 * @property {string} [content_tag] - Indicates the tags related to this content
 * @property {string} [content_template] - Indicates the template used for this content
 * @property {string} [content_type] - Indicates a type of content
 * @property {string} [content_visual] - Indicates the visual related to this content
 * @property {string} [orientation] - Indicates the orientation of this content
 */
/**
 * @typedef Event5
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef ContentDownloaded
 * @property {Campaign} [campaign] - an object containing descriptive properties of a campaign
 * @property {Consumer2} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Content} [content] - Encapsulates data regarding a piece of content
 * @property {Event5} [event] - Encapsulates data regarding general information about this event
 * @property {string} [tactic_summary] - Indicating the name of an Allē tactic used to drive a given outcome
 * @property {string} [view_type] - Indicating a type of view
 */
/**
 * @typedef Event6
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 */
/**
 * @typedef Billing5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address8
 * @property {Billing5} [billing] - Billing address, if known
 * @property {Office5} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping5} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids5
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider5
 * @property {Address8} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids5} [ids] -
 * @property {Media} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ContentScrolled
 * @property {Record<string, any>} [content] - TO DO
 * @property {string} [content_type] - NEEDS DESC
 * @property {Event6} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [expiresAt] - NEEDS DESC
 * @property {string} [fbclid] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Provider5} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Campaign1
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address9
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate3
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined3
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name3
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer3
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address9} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate3} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined3} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name3} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Content1
 * @property {string} [content_campaign] - Indicates the campaign related to this content
 * @property {string} [content_campaign_id] - Uniquely identifies this articles associated with a campaign, by way of a campaign_id
 * @property {string} [content_campaign_name] - Indicates the campaign name behind this content
 * @property {string} [content_concern_area] - Indicates the concern area of this content
 * @property {string} [content_headline] - Indicates the headline related to this content
 * @property {string} [content_id] - Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
 * @property {string} [content_journey] - Indicates the journey theme of this content
 * @property {string} [content_product] - Indicates the product(s) related to this content
 * @property {string} [content_publish_date] - Indates the publish date of this content
 * @property {string} [content_slug] - Indicates the slug of this content
 * @property {string} [content_tag] - Indicates the tags related to this content
 * @property {string} [content_template] - Indicates the template used for this content
 * @property {string} [content_type] - Indicates a type of content
 * @property {string} [content_visual] - Indicates the visual related to this content
 * @property {string} [orientation] - Indicates the orientation of this content
 */
/**
 * @typedef Event7
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing6
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office6
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping6
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address10
 * @property {Billing6} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office6} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping6} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids6
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile5
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider6
 * @property {Address10} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids6} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile5} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef ContentViewed
 * @property {Campaign1} [campaign] - an object containing descriptive properties of a campaign
 * @property {Consumer3} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Content1} [content] - Encapsulates data regarding a piece of content
 * @property {Event7} [event] - Encapsulates data regarding general information about this event
 * @property {Provider6} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [tactic_summary] - Indicating the name of an Allē tactic used to drive a given outcome
 * @property {string} [view_type] - Indicating a type of view
 */
/**
 * @typedef Address11
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate4
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined4
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name4
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer4
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address11} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate4} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined4} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name4} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Content2
 * @property {string} [content_campaign] - Indicates the campaign related to this content
 * @property {string} [content_campaign_id] - Uniquely identifies this articles associated with a campaign, by way of a campaign_id
 * @property {string} [content_campaign_name] - Indicates the campaign name behind this content
 * @property {string} [content_concern_area] - Indicates the concern area of this content
 * @property {string} [content_headline] - Indicates the headline related to this content
 * @property {string} [content_id] - Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
 * @property {string} [content_journey] - Indicates the journey theme of this content
 * @property {string} [content_product] - Indicates the product(s) related to this content
 * @property {string} [content_publish_date] - Indates the publish date of this content
 * @property {string} [content_slug] - Indicates the slug of this content
 * @property {string} [content_tag] - Indicates the tags related to this content
 * @property {string} [content_template] - Indicates the template used for this content
 * @property {string} [content_type] - Indicates a type of content
 * @property {string} [content_visual] - Indicates the visual related to this content
 * @property {string} [orientation] - Indicates the orientation of this content
 */
/**
 * @typedef Event8
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef ElementEngaged
 * @property {string} [action] - Indicates what a user did in order to fire an event
 * @property {Consumer4} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Content2} [content] - Encapsulates data regarding a piece of content
 * @property {string} [element_type] - Indicating a type of user interface element
 * @property {string} [engagement_type] - Indicating a type of engagement
 * @property {Event8} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 */
/**
 * @typedef Campaign2
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address12
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate5
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined5
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name5
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer5
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address12} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate5} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined5} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name5} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event9
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing7
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office7
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping7
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address13
 * @property {Billing7} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office7} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping7} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids7
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile6
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider7
 * @property {Address13} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids7} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile6} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef EnrollmentCompleted
 * @property {any[]} [brands] - Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV
 * @property {Campaign2} [campaign] - an object containing descriptive properties of a campaign
 * @property {string} [completion_type] - Indicates a type of completion
 * @property {Consumer5} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {string} [enrollment_type] - Indicating a type of enrollment
 * @property {Event9} [event] - Encapsulates data regarding general information about this event
 * @property {Provider7} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [zip_code] - Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
 */
/**
 * @typedef Campaign3
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address14
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate6
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined6
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name6
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer6
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address14} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate6} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined6} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name6} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event10
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef EnrollmentStarted
 * @property {any[]} [brands] - Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV
 * @property {Campaign3} [campaign] - an object containing descriptive properties of a campaign
 * @property {Consumer6} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {string} [enrollment_type] - Indicating a type of enrollment
 * @property {Event10} [event] - Encapsulates data regarding general information about this event
 * @property {string} [start_type] - Indicating a type of starting action
 * @property {string} [zip_code] - Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
 */
/**
 * @typedef Address15
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate7
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined7
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name7
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer7
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address15} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate7} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined7} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name7} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event11
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef EnrollmentUpdated
 * @property {Consumer7} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event11} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 */
/**
 * @typedef Address16
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate8
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined8
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name8
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer8
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address16} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate8} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined8} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name8} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event12
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing8
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office8
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping8
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address17
 * @property {Billing8} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office8} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping8} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids8
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile7
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider8
 * @property {Address17} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids8} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile7} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef ErrorTriggered
 * @property {Consumer8} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {string} [error_code] - Indicates a developer-friendly code for error diagnosis
 * @property {string} [error_reason] - Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined
 * @property {string} [error_type] - Indicates a type of error in a human-readable way
 * @property {Event12} [event] - Encapsulates data regarding general information about this event
 * @property {Provider8} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [text] - Indicates a string of text relevant for this event
 * @property {string} [trigger_type] - Indicating a type of trigger
 */
/**
 * @typedef Address18
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate9
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined9
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name9
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer9
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address18} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate9} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined9} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name9} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event13
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef HelpRequested
 * @property {Consumer9} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event13} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [help_type] - Indicating a type of help
 * @property {string} [request_type] - Indicating a type of lead request
 */
/**
 * @typedef Address19
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate10
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined10
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name10
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer10
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address19} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate10} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined10} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name10} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event14
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef InformationCollected
 * @property {string} [collection_type] - Indicates a type of collection
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer10} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event14} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [information_type] - Indicating a type of information
 * @property {Offer} [offer] -
 * @property {string} [offer_slug] - Indicating a description of an offer which is correlated with this event firing
 * @property {string} [registration_type] - Indicating a type of registration
 * @property {number} [step] - Indicates the step number for a form filling activity
 */
/**
 * @typedef Event15
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef InformationDownloaded
 * @property {string} [download_type] - Indicating a type of download
 * @property {Event15} [event] - Encapsulates data regarding general information about this event
 * @property {string} [information_type] - Indicating a type of information
 */
/**
 * @typedef Event16
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing9
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office9
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping9
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address20
 * @property {Billing9} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office9} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping9} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids9
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile8
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider9
 * @property {Address20} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids9} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile8} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadCopied
 * @property {string} [copy_type] - Indicating a type of duplication
 * @property {Event16} [event] - Encapsulates data regarding general information about this event
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Provider9} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [value_copied] - Indicating which value was copied to the device's clipboard
 */
/**
 * @typedef Event17
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer1
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing10
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office10
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping10
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address21
 * @property {Billing10} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office10} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping10} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids10
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile9
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider10
 * @property {Address21} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids10} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile9} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadGenerated
 * @property {Event17} [event] - Encapsulates data regarding general information about this event
 * @property {string} [generation_type] - Indicating a type of reason why something came about
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Offer1} [offer] -
 * @property {Provider10} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Event18
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing11
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office11
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping11
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address22
 * @property {Billing11} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office11} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping11} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids11
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile10
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider11
 * @property {Address22} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids11} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile10} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadStarted
 * @property {Event18} [event] - Encapsulates data regarding general information about this event
 * @property {string} [event_source_url] - This indicates the referring URL
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Provider11} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [request_type] - Indicating a type of lead request
 * @property {string} [start_type] - Indicating a type of starting action
 */
/**
 * @typedef Event19
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing12
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office12
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping12
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address23
 * @property {Billing12} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office12} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping12} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids12
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile11
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider12
 * @property {Address23} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids12} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile11} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadUnmasked
 * @property {Event19} [event] - Encapsulates data regarding general information about this event
 * @property {string} [lead_id] - Indicating the unique id for a given lead
 * @property {string} [lead_source] - Indicating the source of a given lead
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Provider12} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [unmask_type] - Indicating the type of unmask action which took place
 */
/**
 * @typedef Event20
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing13
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office13
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping13
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address24
 * @property {Billing13} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office13} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping13} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids13
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile12
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider13
 * @property {Address24} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids13} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile12} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadUpdated
 * @property {Event20} [event] - Encapsulates data regarding general information about this event
 * @property {string} [lead_id] - Indicating the unique id for a given lead
 * @property {string} [lead_source] - Indicating the source of a given lead
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Provider13} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [update_type] - Indicating a type of update
 */
/**
 * @typedef Event21
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing14
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office14
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping14
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address25
 * @property {Billing14} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office14} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping14} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids14
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile13
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider14
 * @property {Address25} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids14} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile13} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadsConverted
 * @property {string} [conversion_type] - Indicating a type of conversion
 * @property {Event21} [event] - Encapsulates data regarding general information about this event
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {any[]} [offers_redeemed] - Indicating which offers (promotion_id) were used as part of a transactionan array of promotion_id values
 * @property {Provider14} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Event22
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing15
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office15
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping15
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address26
 * @property {Billing15} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office15} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping15} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids15
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile14
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider15
 * @property {Address26} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids15} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile14} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef LeadsDownloaded
 * @property {string} [download_type] - Indicating a type of download
 * @property {Event22} [event] - Encapsulates data regarding general information about this event
 * @property {string} [lead_type] - Indicating the type of a given lead
 * @property {Provider15} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Campaign4
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Event23
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer2
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef LoginStarted
 * @property {Campaign4} [campaign] - an object containing descriptive properties of a campaign
 * @property {Event23} [event] - Encapsulates data regarding general information about this event
 * @property {string} [event_source_url] - This indicates the referring URL
 * @property {string} [login_type] - Indicating a type of login
 * @property {Offer2} [offer] -
 * @property {string} [start_type] - Indicating a type of starting action
 * @property {string} [url] - Indicating the Uniform Resource Locator for a given internet location
 */
/**
 * @typedef Campaign5
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address27
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate11
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined11
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name11
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer11
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address27} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate11} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined11} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name11} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event24
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer3
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef LoginSucceeded
 * @property {Campaign5} [campaign] - an object containing descriptive properties of a campaign
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer11} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event24} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [login_type] - Indicating a type of login
 * @property {Offer3} [offer] -
 * @property {string} [offer_slug] - Indicating a description of an offer which is correlated with this event firing
 * @property {string} [success_type] - Indicating a type of success
 */
/**
 * @typedef Event25
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef LogoutSucceeded
 * @property {Event25} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [logout_type] - Indicating a type of logout
 * @property {string} [success_type] - Indicating a type of success
 */
/**
 * @typedef Campaign6
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Event26
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer4
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef OfferAdded
 * @property {Campaign6} [campaign] - an object containing descriptive properties of a campaign
 * @property {Event26} [event] - Encapsulates data regarding general information about this event
 * @property {Offer4} [offer] -
 */
/**
 * @typedef Campaign7
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address28
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate12
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined12
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name12
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer12
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address28} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate12} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined12} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name12} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event27
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer5
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing16
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office16
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping16
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address29
 * @property {Billing16} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office16} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping16} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids16
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile15
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider16
 * @property {Address29} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids16} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile15} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferClaimed
 * @property {Campaign7} [campaign] - an object containing descriptive properties of a campaign
 * @property {string} [claim_type] - Indicates a type of claim
 * @property {Consumer12} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event27} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {boolean} [new_registration] - Indicating whether or not a consumer was newly registered
 * @property {Offer5} [offer] -
 * @property {Provider16} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Campaign8
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Event28
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer6
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing17
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office17
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping17
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address30
 * @property {Billing17} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office17} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping17} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids17
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile16
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider17
 * @property {Address30} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids17} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile16} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferFailed
 * @property {Campaign8} [campaign] - an object containing descriptive properties of a campaign
 * @property {string} [error_reason] - Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined
 * @property {Event28} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [fail_type] - Indicating a type of failure
 * @property {boolean} [new_registration] - Indicating whether or not a consumer was newly registered
 * @property {Offer6} [offer] -
 * @property {Provider17} [provider] - Encapsulates data regarding general information about a provider
 */
/**
 * @typedef Campaign9
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address31
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate13
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined13
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name13
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer13
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address31} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate13} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined13} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name13} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event29
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer7
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing18
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office18
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping18
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address32
 * @property {Billing18} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office18} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping18} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids18
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile17
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider18
 * @property {Address32} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids18} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile17} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferPresented
 * @property {Campaign9} [campaign] - an object containing descriptive properties of a campaign
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer13} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event29} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Offer7} [offer] -
 * @property {string} [presentation_type] - Indicating a type of presentation
 * @property {Provider18} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [template_id] - Indicating the unique identifier for a given template
 */
/**
 * @typedef Campaign10
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address33
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate14
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined14
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name14
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer14
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address33} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate14} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined14} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name14} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event30
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer8
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing19
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office19
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping19
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address34
 * @property {Billing19} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office19} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping19} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids19
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile18
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider19
 * @property {Address34} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids19} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile18} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferRedeemed
 * @property {Campaign10} [campaign] - an object containing descriptive properties of a campaign
 * @property {string} [checkout_id] - Indicates a unique identifier for a given consumer checkout at a provider's office -
 * @property {Consumer14} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event30} [event] - Encapsulates data regarding general information about this event
 * @property {Offer8} [offer] -
 * @property {Provider19} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [redemption_type] - Indicating a type of redemption
 */
/**
 * @typedef Event31
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer9
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef OfferRemoved
 * @property {Event31} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Offer9} [offer] -
 * @property {string} [removal_type] - Indicating a type of removal
 */
/**
 * @typedef Campaign11
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address35
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate15
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined15
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name15
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer15
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address35} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate15} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined15} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name15} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event32
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer10
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef OfferRequested
 * @property {Campaign11} [campaign] - an object containing descriptive properties of a campaign
 * @property {Consumer15} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event32} [event] - Encapsulates data regarding general information about this event
 * @property {Offer10} [offer] -
 * @property {string} [request_type] - Indicating a type of lead request
 * @property {number} [step] - Indicates the step number for a form filling activity
 */
/**
 * @typedef Address36
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate16
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined16
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name16
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer16
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address36} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate16} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined16} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name16} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event33
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer11
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing20
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office20
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping20
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address37
 * @property {Billing20} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office20} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping20} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids20
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile19
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider20
 * @property {Address37} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids20} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile19} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferShared
 * @property {Consumer16} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {string} [email_shared] - Indicates the email address of a user who will be recipient of the share action
 * @property {Event33} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Offer11} [offer] -
 * @property {Provider20} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [share_type] - Indicating a type of share
 */
/**
 * @typedef Address38
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate17
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined17
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name17
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer17
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address38} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate17} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined17} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name17} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event34
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer12
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing21
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office21
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping21
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address39
 * @property {Billing21} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office21} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping21} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids21
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile20
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider21
 * @property {Address39} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids21} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile20} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferSubmitted
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer17} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event34} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Offer12} [offer] -
 * @property {Provider21} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [submission_type] - Indicating a type of submission
 */
/**
 * @typedef Address40
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate18
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined18
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name18
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer18
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address40} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate18} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined18} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name18} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event35
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer13
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing22
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office22
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping22
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address41
 * @property {Billing22} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office22} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping22} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids22
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile21
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider22
 * @property {Address41} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids22} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile21} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef OfferSuppressed
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer18} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event35} [event] - Encapsulates data regarding general information about this event
 * @property {Offer13} [offer] -
 * @property {Provider22} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [reason] - Indicates a human-readable explanation for why a given event happened
 * @property {string} [suppression_type] - Indicating a type of suppressing action
 */
/**
 * @typedef Content3
 * @property {string} [content_campaign] - Indicates the campaign related to this content
 * @property {string} [content_campaign_id] - Uniquely identifies this articles associated with a campaign, by way of a campaign_id
 * @property {string} [content_campaign_name] - Indicates the campaign name behind this content
 * @property {string} [content_concern_area] - Indicates the concern area of this content
 * @property {string} [content_headline] - Indicates the headline related to this content
 * @property {string} [content_id] - Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
 * @property {string} [content_journey] - Indicates the journey theme of this content
 * @property {string} [content_product] - Indicates the product(s) related to this content
 * @property {string} [content_publish_date] - Indates the publish date of this content
 * @property {string} [content_slug] - Indicates the slug of this content
 * @property {string} [content_tag] - Indicates the tags related to this content
 * @property {string} [content_template] - Indicates the template used for this content
 * @property {string} [content_type] - Indicates a type of content
 * @property {string} [content_visual] - Indicates the visual related to this content
 * @property {string} [orientation] - Indicates the orientation of this content
 */
/**
 * @typedef Event36
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing23
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office23
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping23
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address42
 * @property {Billing23} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office23} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping23} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids23
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile22
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider23
 * @property {Address42} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids23} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile22} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef PageScrolled
 * @property {Content3} [content] - Encapsulates data regarding a piece of content
 * @property {Event36} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [page_type] - Indicating a type of page
 * @property {number} [percent_scrolled] - Indicating the % of the page/screen which was scrolled fires at 25/50/75/90 percent
 * @property {Provider23} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [scroll_type] - Indicating a type of scroll
 * @property {string} [slug] - Indicating the last part of a URL address that serves as a unique identifier of the page
 */
/**
 * @typedef Event37
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef PasswordForgotten
 * @property {Event37} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [forgetfulness_type] - Indicating a reason why something was forgotten
 * @property {string} [password_type] - Indicating a type of password
 */
/**
 * @typedef Event38
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef PasswordUpdated
 * @property {Event38} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [password_type] - Indicating a type of password
 * @property {string} [update_type] - Indicating a type of update
 */
/**
 * @typedef Event39
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef ProviderContacted
 * @property {Event39} [event] - Encapsulates data regarding general information about this event
 */
/**
 * @typedef Event40
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Query
 * @property {Record<string, any>} [filter_data] - Freeform object used to qualify searches. Use name value pairs as specified by business needs
 * @property {boolean} [is_current_location] - Indicates if the current location of the device was used in this query
 * @property {number} [radius] - Indicates the radius from the zip code related to this query
 * @property {string} [search_text] - Indicates the strings used to narrow down this query
 * @property {string} [sort_type] - Indicates the type of sorting used as part of this query, e.g. 'ascending'
 * @property {string} [zip_code] - Indicates the zip code related to this query
 */
/**
 * @typedef ProviderSearched
 * @property {Event40} [event] - Encapsulates data regarding general information about this event
 * @property {string} [provider_type] - Indicating a type of provider
 * @property {Query} [query] - an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
 * @property {string} [search_type] - Indicating a type of search
 * @property {string} [zip_code] - Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0
 */
/**
 * @typedef Address43
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate19
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined19
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name19
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer19
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address43} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate19} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined19} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name19} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event41
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing24
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office24
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping24
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address44
 * @property {Billing24} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office24} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping24} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids24
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile23
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider24
 * @property {Address44} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids24} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile23} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef ProviderViewed
 * @property {Consumer19} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event41} [event] - Encapsulates data regarding general information about this event
 * @property {string} [event_source_url] - This indicates the referring URL
 * @property {Provider24} [provider] - Encapsulates data regarding general information about a provider
 * @property {number} [provider_count] -
 * @property {string} [provider_type] - Indicating a type of provider
 * @property {string} [results_page] - Indicating the pagination number of the search result experience
 * @property {string} [view_type] - Indicating a type of view
 */
/**
 * @typedef Campaign12
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Address45
 * @property {string} [city] - Indicates the city this consumer, sits in @address object
 * @property {string} [country] - Indicates the country this consumer, sits in @address object
 * @property {string} [county] - Indicates the county this consumer, sits in @address object
 * @property {string} [state] - Indicates the state of this consumer, sits in @address object
 * @property {string} [street] - Indicates the street this consumer, sits in @address object
 * @property {string} [zip_code] - Indicates the zip code of this consumer, sits in @address object
 */
/**
 * @typedef Birthdate20
 * @property {number} [day] - Indicates the birthdate day of this consumer, sits in @birthdate object
 * @property {number} [month] - Indicates the birthdate month of this consumer, sits in @birthdate object
 * @property {number} [year] - Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.
 */
/**
 * @typedef Joined20
 * @property {string} [alle] - Indicates the alle join date of this consumer, sits in @joined object
 * @property {string} [bd] - Indicates the alle join date of this consumer, sits in @joined object
 */
/**
 * @typedef Name20
 * @property {string} [first] - the consumers first (given) name
 * @property {string} [last] - the consumers last (family) name
 * @property {string} [middle] - the consumers middle name
 */
/**
 * @typedef Consumer20
 * @property {string} [] - Indicates the email address of the consumer
 * @property {Address45} [address] - Encapsulates data regarding the consumer's address
 * @property {string} [alle_id] - Uniquely identifies this consumer, by way of an alle ID
 * @property {Birthdate20} [birthdate] - Encapsulates data regarding the consumer's birthdate
 * @property {string} [email] - indicates the email address of the user.
 * @property {Joined20} [joined] - Encapsulates data regarding the dates in which the consumer joined our programs
 * @property {Name20} [name] - encapsulates data regarding the consumer's name
 * @property {string} [phone] - Indicates the phone number of this consumer
 */
/**
 * @typedef Event42
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer14
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing25
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office25
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping25
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address46
 * @property {Billing25} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office25} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping25} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids25
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile24
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider25
 * @property {Address46} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids25} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile24} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef RegistrationCompleted
 * @property {Campaign12} [campaign] - an object containing descriptive properties of a campaign
 * @property {any[]} [consent_type] - Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location
 * @property {Consumer20} [consumer] - Encapsulates data regarding an Allē consumer
 * @property {Event42} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {Offer14} [offer] -
 * @property {string} [offer_slug] - Indicating a description of an offer which is correlated with this event firing
 * @property {Provider25} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [registration_type] - Indicating a type of registration
 */
/**
 * @typedef Campaign13
 * @property {string} [campaign_id] - Uniquely identifies this instance of a campaign, as generated by the Grow backend
 * @property {string} [campaign_id_source] - Indicates the source of truth for generating the campaign_id
 * @property {string} [campaign_name] - Indicates the name of this campaign
 * @property {string} [campaign_template_id] - Uniquely identifies this campaign template, as generated by the Grow backend
 * @property {string} [campaign_type] - Indicates the type of this campaign
 */
/**
 * @typedef Event43
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Offer15
 * @property {string} [offer_activation] - Indicates the activation type of this offer
 * @property {string} [offer_benefactor] - IIndicates the institution which financially backed the offer
 * @property {any[]} [offer_brands] - Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS
 * @property {string} [offer_claimed] - Indicates whether or not this offer has been claimed
 * @property {string} [offer_expiration] - Indicates the expiration of this offer
 * @property {string} [offer_name] - Indicates the display name of this offer
 * @property {string} [offer_name_internal] - Indicates the internal name of this offer, which may differ from the display name used by offer_name
 * @property {string} [offer_slug] - Indicates the slug of this offer
 * @property {string} [offer_type] - Indicates a type of offer
 * @property {string} [offer_value] - Indicates the value of this offer in USD
 * @property {string} [promotion_id] - Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder
 */
/**
 * @typedef Billing26
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office26
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping26
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address47
 * @property {Billing26} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office26} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping26} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids26
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile25
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider26
 * @property {Address47} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids26} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile25} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef RegistrationStarted
 * @property {Campaign13} [campaign] - an object containing descriptive properties of a campaign
 * @property {Event43} [event] - Encapsulates data regarding general information about this event
 * @property {Offer15} [offer] -
 * @property {Provider26} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [registration_type] - Indicating a type of registration
 * @property {string} [start_type] - Indicating a type of starting action
 */
/**
 * @typedef Event44
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Query1
 * @property {Record<string, any>} [filter_data] - Freeform object used to qualify searches. Use name value pairs as specified by business needs
 * @property {boolean} [is_current_location] - Indicates if the current location of the device was used in this query
 * @property {number} [radius] - Indicates the radius from the zip code related to this query
 * @property {string} [search_text] - Indicates the strings used to narrow down this query
 * @property {string} [sort_type] - Indicates the type of sorting used as part of this query, e.g. 'ascending'
 * @property {string} [zip_code] - Indicates the zip code related to this query
 */
/**
 * @typedef ResultsFiltered
 * @property {Event44} [event] - Encapsulates data regarding general information about this event
 * @property {string} [filter_type] - Indicating a type of filter
 * @property {Query1} [query] - an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
 * @property {number} [results_count] -
 * @property {string} [results_type] - Indicating a type of search results
 */
/**
 * @typedef Event45
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing27
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office27
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping27
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address48
 * @property {Billing27} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office27} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping27} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids27
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile26
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider27
 * @property {Address48} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids27} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile26} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef Query2
 * @property {Record<string, any>} [filter_data] - Freeform object used to qualify searches. Use name value pairs as specified by business needs
 * @property {boolean} [is_current_location] - Indicates if the current location of the device was used in this query
 * @property {number} [radius] - Indicates the radius from the zip code related to this query
 * @property {string} [search_text] - Indicates the strings used to narrow down this query
 * @property {string} [sort_type] - Indicates the type of sorting used as part of this query, e.g. 'ascending'
 * @property {string} [zip_code] - Indicates the zip code related to this query
 */
/**
 * @typedef ResultsViewed
 * @property {Event45} [event] - Encapsulates data regarding general information about this event
 * @property {Provider27} [provider] - Encapsulates data regarding general information about a provider
 * @property {Query2} [query] - an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed
 * @property {number} [results_count] -
 * @property {string} [results_type] - Indicating a type of search results
 * @property {string} [view_type] - Indicating a type of view
 */
/**
 * @typedef Content4
 * @property {string} [content_campaign] - Indicates the campaign related to this content
 * @property {string} [content_campaign_id] - Uniquely identifies this articles associated with a campaign, by way of a campaign_id
 * @property {string} [content_campaign_name] - Indicates the campaign name behind this content
 * @property {string} [content_concern_area] - Indicates the concern area of this content
 * @property {string} [content_headline] - Indicates the headline related to this content
 * @property {string} [content_id] - Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva
 * @property {string} [content_journey] - Indicates the journey theme of this content
 * @property {string} [content_product] - Indicates the product(s) related to this content
 * @property {string} [content_publish_date] - Indates the publish date of this content
 * @property {string} [content_slug] - Indicates the slug of this content
 * @property {string} [content_tag] - Indicates the tags related to this content
 * @property {string} [content_template] - Indicates the template used for this content
 * @property {string} [content_type] - Indicates a type of content
 * @property {string} [content_visual] - Indicates the visual related to this content
 * @property {string} [orientation] - Indicates the orientation of this content
 */
/**
 * @typedef Event46
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing28
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office28
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping28
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address49
 * @property {Billing28} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office28} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping28} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids28
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile27
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider28
 * @property {Address49} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids28} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile27} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef SiteNavigated
 * @property {string} [card_position] - Indicates the position of a given content card relative to other content cards
 * @property {Content4} [content] - Encapsulates data regarding a piece of content
 * @property {Event46} [event] - Encapsulates data regarding general information about this event
 * @property {string} [event_source_url] - This indicates the referring URL
 * @property {number} [expiresAt] - Indicating some form of an expiration - no idea where this is generated
 * @property {string} [navigation_type] - Indicating a type of navigation
 * @property {Provider28} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [section] - Indicating the name of a content section
 * @property {number} [section_position] - Indicating the position count of a given piece of content within a section
 * @property {string} [site_type] - Indicating a type of site
 * @property {string} [text] - Indicates a string of text relevant for this event
 * @property {string} [url] - Indicating the Uniform Resource Locator for a given internet location
 */
/**
 * @typedef Event47
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef Billing29
 * @property {string} [city] - Indicates the billing city of this provider, sits in @address.billing object
 * @property {string} [country] - Indicates the billing country of this provider, sits in @address.billing object
 * @property {string} [county] - Indicates the billing county of this provider, sits in @address.billing object
 * @property {string} [street] - Indicates the billing street of this provider, sits in @address.billing object
 * @property {string} [zip_code] - Indicates the billing zip code of this provider, sits in @address.billing object
 */
/**
 * @typedef Office29
 * @property {string} [city] - Indicates the office city this provider, sits in @address.office object
 * @property {string} [country] - Indicates the office country this provider, sits in @address.office object
 * @property {string} [county] - Indicates the office county this provider, sits in @address.office object
 * @property {string} [street] - Indicates the office street of this provider, sits in @address.office object
 * @property {string} [zip_code] - Indicates the office zip code this provider, sits in @address.office object
 */
/**
 * @typedef Shipping29
 * @property {string} [city] - Indicates the shipping city of this provider, sits in @address.shipping object
 * @property {string} [country] - Indicates the shipping country of this provider, sits in @address.shipping object
 * @property {string} [county] - Indicates the shipping county this provider, sits in @address.shipping object
 * @property {string} [street] - Indicates the shipping street of this provider, sits in @address.shipping object
 * @property {string} [zip_code] - Indicates the shipping zip code of this provider, sits in @address.shipping object
 */
/**
 * @typedef Address50
 * @property {Billing29} [billing] - Encapsulates data regarding the provider's billing address
 * @property {Office29} [office] - Encapsulates data regarding the provider's office address
 * @property {Shipping29} [shipping] - Encapsulates data regarding the provider's shipping address
 */
/**
 * @typedef Ids29
 * @property {string} [business_id] - Uniquely identifies this business, by way of a business_id provided by A4B
 * @property {string} [location_id] - Uniquely identifies this location, by way of a location_id provided by A4B
 * @property {string} [provider_id] - Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers
 */
/**
 * @typedef Profile28
 * @property {string} [booking_url] - Indicates the booking url of this business location, sits in @profile object
 * @property {string} [channel] - Indicates the channel of this business location, sits in @profile object
 * @property {string} [consultation_cost] - Indicates the consultation cost of this business location, sits in @profile object
 * @property {boolean} [consultation_enabled] - Indicates if consultations are enabled for this business location, sits in @profile object
 * @property {string} [contact_email_address] - Indicates the contact email address of this business location, sits in @profile object
 * @property {boolean} [contact_info_added] - Indicates if contact information was added to this profile, sits in @profile object
 * @property {string} [contact_instagram_handle] - Indicates the instagram handle of this business location, sits in @profile object
 * @property {boolean} [hide_consultation_form] - Indicates if the consultation form of this business location is hidden or shown, sits in @profile object
 * @property {boolean} [hours_added] - Indicates if hours of operation were updated for this business location, sits in @profile object
 * @property {boolean} [is_fee_towards_treatment_cost] - Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object
 * @property {number} [location_count] - Indicates the number of locations which were updated, sits in @profile object
 * @property {any[]} [locations_updated] - Indicates which locations were updated, sits in @profile object
 * @property {number} [num_days_closed] - Indicates the number of days closed at this business location, sits in @profile object
 * @property {number} [num_days_open] - Indicates the number of days open at this business location, sits in @profile object
 * @property {number} [num_days_set] - Indicates the ___ of this business location, sits in @profile object
 * @property {number} [num_gallery_photos] - Indicates the number of gallery photos shown at this business location, sits in @profile object
 * @property {number} [num_products] - Total number of products being shown in the profile
 * @property {string} [phone_number] - Indicates the phone number of this business location, sits in @profile object
 * @property {boolean} [photos_added] - Indicating whether or not photos were added
 * @property {boolean} [practitioner_added] - Indicating whether or not a practitioner was added
 * @property {number} [profile_completeness] - Indicates the level of completeness of this profile, sits in @profile object
 * @property {string} [publish_type] - Indicating a type of publish
 * @property {string} [send_requests_to_email] - Indicates the email where lead requests are sent for this profile, sits in @profile object
 * @property {boolean} [success] - Indicates the success of a publish event, sits in @profile object
 * @property {boolean} [treatments_added] - Indicates if treatments were added to this profile, sits in @profile object
 */
/**
 * @typedef Provider29
 * @property {Address50} [address] - Encapsulates data regarding the provider's addresses
 * @property {Record<string, any>} [contacts] - Indicates a freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - Indicates the email address of this provider
 * @property {Ids29} [ids] - Encapsulates data regarding the ids used to identify this provider
 * @property {Record<string, any>} [media_urls] - freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.
 * @property {string} [name] - Indicates the name of this provider
 * @property {string} [phone] - Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.
 * @property {string} [phone_extension] - Indicates the phone number extension of this provider
 * @property {Profile28} [profile] - Encapsulates data regarding the provider profile related to a business location
 * @property {string} [url] - Indicates the website url of this provider
 */
/**
 * @typedef TransitionViewed
 * @property {any[]} [consent_type] -
 * @property {Event47} [event] - Encapsulates data regarding general information about this event
 * @property {Provider29} [provider] - Encapsulates data regarding general information about a provider
 * @property {string} [tactic_summary] -
 * @property {string} [transition_type] -
 * @property {string} [view_type] -
 */
/**
 * @typedef Event48
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef VideoEnded
 * @property {Event48} [event] - Encapsulates data regarding general information about this event
 * @property {string} [title] -
 * @property {string} [url] -
 * @property {string} [video_type] -
 */
/**
 * @typedef Event49
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef VideoPaused
 * @property {Event49} [event] - Encapsulates data regarding general information about this event
 * @property {string} [title] -
 * @property {string} [url] -
 * @property {string} [video_type] -
 */
/**
 * @typedef Event50
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef VideoScrolled
 * @property {Event50} [event] - Encapsulates data regarding general information about this event
 * @property {string} [title] -
 * @property {string} [url] -
 * @property {string} [video_type] -
 */
/**
 * @typedef Event51
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.
 * @property {string} [event_step] - Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.
 * @property {string} [event_type] - Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.
 * @property {string} [user_status] - Indicates whether or not this user is logged in or not. logged|not logged
 * @property {string} [user_type] - Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.
 */
/**
 * @typedef VideoStarted
 * @property {Event51} [event] - Encapsulates data regarding general information about this event
 * @property {number} [expiresAt] -
 * @property {string} [start_type] -
 * @property {string} [title] -
 * @property {string} [url] -
 * @property {string} [video_type] -
 */

/**
 * Whereby, an automated phone call, was attached to an account
 *
 * @param {AutocallAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autocallAdded(
  props?: AutocallAdded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, an automated phone call, was attached to an account',
    properties: {
      context: {},
      properties: {
        properties: {
          autocall_type: {
            description: 'Indicates a type of autocall',
            id: '/properties/properties/properties/event/properties/autocall_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Autocall Added',
    type: 'object',
  };
  const message = {
    event: 'Autocall Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Autocall Added',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an automated phone call, was accepted by the end user or system
 *
 * @param {AutocallApproved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autocallApproved(
  props?: AutocallApproved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an automated phone call, was accepted by the end user or system',
    properties: {
      context: {},
      properties: {
        properties: {
          approval_type: {
            description: 'Indicates a type of approval',
            id: '/properties/properties/properties/event/properties/approval_type',
            type: 'string',
          },
          autocall_type: {
            description: 'Indicates a type of autocall',
            id: '/properties/properties/properties/event/properties/autocall_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Autocall Approved',
    type: 'object',
  };
  const message = {
    event: 'Autocall Approved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Autocall Approved',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an automated phone call, was solicited from a system or person
 *
 * @param {AutocallRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autocallRequested(
  props?: AutocallRequested,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an automated phone call, was solicited from a system or person',
    properties: {
      context: {},
      properties: {
        properties: {
          autocall_type: {
            description: 'Indicates a type of autocall',
            id: '/properties/properties/properties/event/properties/autocall_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Autocall Requested',
    type: 'object',
  };
  const message = {
    event: 'Autocall Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Autocall Requested',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, the permission for something to happen or agreement to do something, was accepted by the end user or system
 *
 * @param {ConsentApproved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consentApproved(
  props?: ConsentApproved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, the permission for something to happen or agreement to do something, was accepted by the end user or system',
    properties: {
      context: {},
      properties: {
        properties: {
          approval_type: {
            description: 'Indicates a type of approval',
            id: '/properties/properties/properties/event/properties/approval_type',
            type: 'string',
          },
          consent_id: {
            description:
              'Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)',
            id: '/properties/properties/properties/event/properties/consent_id',
            type: 'string',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Consent Approved',
    type: 'object',
  };
  const message = {
    event: 'Consent Approved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Consent Approved',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, The permission for something to happen or agreement to do something, was denied
 *
 * @param {ConsentDeclined} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function consentDeclined(
  props?: ConsentDeclined,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, The permission for something to happen or agreement to do something, was denied',
    properties: {
      context: {},
      properties: {
        properties: {
          consent_id: {
            description:
              'Uniquely identifies this consent item, by way of the value of Code_Id from Contentful: (Element > Setting)',
            id: '/properties/properties/properties/event/properties/consent_id',
            type: 'string',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          decline_type: {
            description: 'Indicating a type of decline',
            id: '/properties/properties/properties/event/properties/decline_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Consent Declined',
    type: 'object',
  };
  const message = {
    event: 'Consent Declined',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Consent Declined',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a message or article made available by Allē, was pulled down from the internet
 *
 * @param {ContentDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDownloaded(
  props?: ContentDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a message or article made available by Allē, was pulled down from the internet',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          content: {
            description: 'Encapsulates data regarding a piece of content',
            id: '/properties/properties/properties/content',
            properties: {
              content_campaign: {
                description: 'Indicates the campaign related to this content',
                id: '/properties/properties/properties/content/properties/content_campaign',
                type: 'string',
              },
              content_campaign_id: {
                description:
                  'Uniquely identifies this articles associated with a campaign, by way of a campaign_id',
                id: '/properties/properties/properties/content/properties/content_campaign_id',
                type: 'string',
              },
              content_campaign_name: {
                description: 'Indicates the campaign name behind this content',
                id: '/properties/properties/properties/content/properties/content_campaign_name',
                type: 'string',
              },
              content_concern_area: {
                description: 'Indicates the concern area of this content',
                id: '/properties/properties/properties/content/properties/content_concern_area',
                type: 'string',
              },
              content_headline: {
                description: 'Indicates the headline related to this content',
                id: '/properties/properties/properties/content/properties/content_headline',
                type: 'string',
              },
              content_id: {
                description:
                  "Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva",
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              content_journey: {
                description: 'Indicates the journey theme of this content',
                id: '/properties/properties/properties/content/properties/content_journey',
                type: 'string',
              },
              content_product: {
                description: 'Indicates the product(s) related to this content',
                id: '/properties/properties/properties/content/properties/content_product',
                type: 'string',
              },
              content_publish_date: {
                description: 'Indates the publish date of this content',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/content_publish_date',
                type: 'string',
              },
              content_slug: {
                description: 'Indicates the slug of this content',
                id: '/properties/properties/properties/content/properties/content_slug',
                type: 'string',
              },
              content_tag: {
                description: 'Indicates the tags related to this content',
                id: '/properties/properties/properties/content/properties/content_tag',
                type: 'string',
              },
              content_template: {
                description: 'Indicates the template used for this content',
                id: '/properties/properties/properties/content/properties/content_template',
                type: 'string',
              },
              content_type: {
                description: 'Indicates a type of content',
                id: '/properties/properties/properties/content/properties/content_type',
                type: 'string',
              },
              content_visual: {
                description: 'Indicates the visual related to this content',
                id: '/properties/properties/properties/content/properties/content_visual',
                type: 'string',
              },
              orientation: {
                description: 'Indicates the orientation of this content',
                id: '/properties/properties/properties/content/properties/orientation',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          tactic_summary: {
            description:
              'Indicating the name of an Allē tactic used to drive a given outcome',
            id: '/properties/properties/properties/event/properties/tactic_summary',
            type: 'string',
          },
          view_type: {
            description: 'Indicating a type of view',
            id: '/properties/properties/properties/event/properties/view_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Content Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Downloaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, The permission for something to happen or agreement to do something, was seen by an end user
 *
 * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentScrolled(
  props?: ContentScrolled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, The permission for something to happen or agreement to do something, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          content: {
            description: 'TO DO',
            id: '/properties/properties/properties/content',
            properties: {},
            required: [],
            type: 'object',
          },
          content_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'string',
          },
          fbclid: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/fbclid',
            type: 'string',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Scrolled',
    type: 'object',
  };
  const message = {
    event: 'Content Scrolled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Scrolled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a message or article made available by Allē, was seen by an end user
 *
 * @param {ContentViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentViewed(
  props?: ContentViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a message or article made available by Allē, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          content: {
            description: 'Encapsulates data regarding a piece of content',
            id: '/properties/properties/properties/content',
            properties: {
              content_campaign: {
                description: 'Indicates the campaign related to this content',
                id: '/properties/properties/properties/content/properties/content_campaign',
                type: 'string',
              },
              content_campaign_id: {
                description:
                  'Uniquely identifies this articles associated with a campaign, by way of a campaign_id',
                id: '/properties/properties/properties/content/properties/content_campaign_id',
                type: 'string',
              },
              content_campaign_name: {
                description: 'Indicates the campaign name behind this content',
                id: '/properties/properties/properties/content/properties/content_campaign_name',
                type: 'string',
              },
              content_concern_area: {
                description: 'Indicates the concern area of this content',
                id: '/properties/properties/properties/content/properties/content_concern_area',
                type: 'string',
              },
              content_headline: {
                description: 'Indicates the headline related to this content',
                id: '/properties/properties/properties/content/properties/content_headline',
                type: 'string',
              },
              content_id: {
                description:
                  "Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva",
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              content_journey: {
                description: 'Indicates the journey theme of this content',
                id: '/properties/properties/properties/content/properties/content_journey',
                type: 'string',
              },
              content_product: {
                description: 'Indicates the product(s) related to this content',
                id: '/properties/properties/properties/content/properties/content_product',
                type: 'string',
              },
              content_publish_date: {
                description: 'Indates the publish date of this content',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/content_publish_date',
                type: 'string',
              },
              content_slug: {
                description: 'Indicates the slug of this content',
                id: '/properties/properties/properties/content/properties/content_slug',
                type: 'string',
              },
              content_tag: {
                description: 'Indicates the tags related to this content',
                id: '/properties/properties/properties/content/properties/content_tag',
                type: 'string',
              },
              content_template: {
                description: 'Indicates the template used for this content',
                id: '/properties/properties/properties/content/properties/content_template',
                type: 'string',
              },
              content_type: {
                description: 'Indicates a type of content',
                id: '/properties/properties/properties/content/properties/content_type',
                type: 'string',
              },
              content_visual: {
                description: 'Indicates the visual related to this content',
                id: '/properties/properties/properties/content/properties/content_visual',
                type: 'string',
              },
              orientation: {
                description: 'Indicates the orientation of this content',
                id: '/properties/properties/properties/content/properties/orientation',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          tactic_summary: {
            description:
              'Indicating the name of an Allē tactic used to drive a given outcome',
            id: '/properties/properties/properties/event/properties/tactic_summary',
            type: 'string',
          },
          view_type: {
            description: 'Indicating a type of view',
            id: '/properties/properties/properties/event/properties/view_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Viewed',
    type: 'object',
  };
  const message = {
    event: 'Content Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a distinct part of our user interface, was interacted with
 *
 * @param {ElementEngaged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementEngaged(
  props?: ElementEngaged,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a distinct part of our user interface, was interacted with',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: 'Indicates what a user did in order to fire an event',
            id: '/properties/properties/properties/event/properties/action',
            type: 'string',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          content: {
            description: 'Encapsulates data regarding a piece of content',
            id: '/properties/properties/properties/content',
            properties: {
              content_campaign: {
                description: 'Indicates the campaign related to this content',
                id: '/properties/properties/properties/content/properties/content_campaign',
                type: 'string',
              },
              content_campaign_id: {
                description:
                  'Uniquely identifies this articles associated with a campaign, by way of a campaign_id',
                id: '/properties/properties/properties/content/properties/content_campaign_id',
                type: 'string',
              },
              content_campaign_name: {
                description: 'Indicates the campaign name behind this content',
                id: '/properties/properties/properties/content/properties/content_campaign_name',
                type: 'string',
              },
              content_concern_area: {
                description: 'Indicates the concern area of this content',
                id: '/properties/properties/properties/content/properties/content_concern_area',
                type: 'string',
              },
              content_headline: {
                description: 'Indicates the headline related to this content',
                id: '/properties/properties/properties/content/properties/content_headline',
                type: 'string',
              },
              content_id: {
                description:
                  "Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva",
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              content_journey: {
                description: 'Indicates the journey theme of this content',
                id: '/properties/properties/properties/content/properties/content_journey',
                type: 'string',
              },
              content_product: {
                description: 'Indicates the product(s) related to this content',
                id: '/properties/properties/properties/content/properties/content_product',
                type: 'string',
              },
              content_publish_date: {
                description: 'Indates the publish date of this content',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/content_publish_date',
                type: 'string',
              },
              content_slug: {
                description: 'Indicates the slug of this content',
                id: '/properties/properties/properties/content/properties/content_slug',
                type: 'string',
              },
              content_tag: {
                description: 'Indicates the tags related to this content',
                id: '/properties/properties/properties/content/properties/content_tag',
                type: 'string',
              },
              content_template: {
                description: 'Indicates the template used for this content',
                id: '/properties/properties/properties/content/properties/content_template',
                type: 'string',
              },
              content_type: {
                description: 'Indicates a type of content',
                id: '/properties/properties/properties/content/properties/content_type',
                type: 'string',
              },
              content_visual: {
                description: 'Indicates the visual related to this content',
                id: '/properties/properties/properties/content/properties/content_visual',
                type: 'string',
              },
              orientation: {
                description: 'Indicates the orientation of this content',
                id: '/properties/properties/properties/content/properties/orientation',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          element_type: {
            description: 'Indicating a type of user interface element',
            id: '/properties/properties/properties/event/properties/element_type',
            type: 'string',
          },
          engagement_type: {
            description: 'Indicating a type of engagement',
            id: '/properties/properties/properties/event/properties/engagement_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Element Engaged',
    type: 'object',
  };
  const message = {
    event: 'Element Engaged',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Element Engaged',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a process of signing up for a program within a parent system, was finished/committed to a database
 *
 * @param {EnrollmentCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enrollmentCompleted(
  props?: EnrollmentCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a process of signing up for a program within a parent system, was finished/committed to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          brands: {
            description:
              'Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV',
            id: '/properties/properties/properties/event/properties/brands',
            type: 'array',
          },
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          completion_type: {
            description: 'Indicates a type of completion',
            id: '/properties/properties/properties/event/properties/completion_type',
            type: 'string',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          enrollment_type: {
            description: 'Indicating a type of enrollment',
            id: '/properties/properties/properties/event/properties/enrollment_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          zip_code: {
            description:
              'Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0',
            id: '/properties/properties/properties/event/properties/zip_code',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Enrollment Completed',
    type: 'object',
  };
  const message = {
    event: 'Enrollment Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Enrollment Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a process of signing up for a program within a parent system, was set in operation
 *
 * @param {EnrollmentStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enrollmentStarted(
  props?: EnrollmentStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a process of signing up for a program within a parent system, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          brands: {
            description:
              'Indicates brands touched by this event in an array.ALLE | BTXC | CSCP | CLTN | DMGL | JVDM | KYBL | LTSS | NTRL | RVLV | SKMD | SKNV',
            id: '/properties/properties/properties/event/properties/brands',
            type: 'array',
          },
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          enrollment_type: {
            description: 'Indicating a type of enrollment',
            id: '/properties/properties/properties/event/properties/enrollment_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          start_type: {
            description: 'Indicating a type of starting action',
            id: '/properties/properties/properties/event/properties/start_type',
            type: 'string',
          },
          zip_code: {
            description:
              'Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0',
            id: '/properties/properties/properties/event/properties/zip_code',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Enrollment Started',
    type: 'object',
  };
  const message = {
    event: 'Enrollment Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Enrollment Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a process of signing up for a program within a parent system, was changed from an older version to a newer
 *
 * @param {EnrollmentUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enrollmentUpdated(
  props?: EnrollmentUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a process of signing up for a program within a parent system, was changed from an older version to a newer',
    properties: {
      context: {},
      properties: {
        properties: {
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Enrollment Updated',
    type: 'object',
  };
  const message = {
    event: 'Enrollment Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Enrollment Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an instruction that is either not recognized by an operating system or is in violation of rules, was invoked by our system
 *
 * @param {ErrorTriggered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorTriggered(
  props?: ErrorTriggered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an instruction that is either not recognized by an operating system or is in violation of rules, was invoked by our system',
    properties: {
      context: {},
      properties: {
        properties: {
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          error_code: {
            description:
              'Indicates a developer-friendly code for error diagnosis',
            id: '/properties/properties/properties/event/properties/error_code',
            type: 'string',
          },
          error_reason: {
            description:
              'Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined',
            id: '/properties/properties/properties/event/properties/error_reason',
            type: 'string',
          },
          error_type: {
            description: 'Indicates a type of error in a human-readable way ',
            id: '/properties/properties/properties/event/properties/error_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          text: {
            description: 'Indicates a string of text relevant for this event',
            id: '/properties/properties/properties/event/properties/text',
            type: 'string',
          },
          trigger_type: {
            description: 'Indicating a type of trigger',
            id: '/properties/properties/properties/event/properties/trigger_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Error Triggered',
    type: 'object',
  };
  const message = {
    event: 'Error Triggered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Error Triggered',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, assistance, was solicited from a system or person
 *
 * @param {HelpRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpRequested(
  props?: HelpRequested,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, assistance, was solicited from a system or person',
    properties: {
      context: {},
      properties: {
        properties: {
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          help_type: {
            description: 'Indicating a type of help',
            id: '/properties/properties/properties/event/properties/help_type',
            type: 'string',
          },
          request_type: {
            description: 'Indicating a type of lead request',
            id: '/properties/properties/properties/event/properties/request_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Help Requested',
    type: 'object',
  };
  const message = {
    event: 'Help Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Help Requested',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, data, was acquired
 *
 * @param {InformationCollected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function informationCollected(
  props?: InformationCollected,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, data, was acquired',
    properties: {
      context: {},
      properties: {
        properties: {
          collection_type: {
            description: 'Indicates a type of collection',
            id: '/properties/properties/properties/event/properties/collection_type',
            type: 'string',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          information_type: {
            description: 'Indicating a type of information',
            id: '/properties/properties/properties/event/properties/information_type',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer_slug: {
            description:
              'Indicating a description of an offer which is correlated with this event firing',
            id: '/properties/properties/properties/event/properties/offer_slug',
            type: 'string',
          },
          registration_type: {
            description: 'Indicating a type of registration',
            id: '/properties/properties/properties/event/properties/registration_type',
            type: 'string',
          },
          step: {
            description:
              'Indicates the step number for a form filling activity',
            id: '/properties/properties/properties/event/properties/step',
            type: 'number',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Information Collected',
    type: 'object',
  };
  const message = {
    event: 'Information Collected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Information Collected',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, data, was pulled down from the internet
 *
 * @param {InformationDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function informationDownloaded(
  props?: InformationDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, data, was pulled down from the internet',
    properties: {
      context: {},
      properties: {
        properties: {
          download_type: {
            description: 'Indicating a type of download',
            id: '/properties/properties/properties/event/properties/download_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          information_type: {
            description: 'Indicating a type of information',
            id: '/properties/properties/properties/event/properties/information_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Information Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Information Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Information Downloaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a consumer who might become a patient, was replicated to be pasted somewhere else
 *
 * @param {LeadCopied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadCopied(
  props?: LeadCopied,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a consumer who might become a patient, was replicated to be pasted somewhere else',
    properties: {
      context: {},
      properties: {
        properties: {
          copy_type: {
            description: 'Indicating a type of duplication',
            id: '/properties/properties/properties/event/properties/copy_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          value_copied: {
            description:
              "Indicating which value was copied to the device's clipboard",
            id: '/properties/properties/properties/event/properties/value_copied',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Lead Copied',
    type: 'object',
  };
  const message = {
    event: 'Lead Copied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Lead Copied',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a consumer who might become a patient, was passed to a business to later be contacted
 *
 * @param {LeadGenerated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadGenerated(
  props?: LeadGenerated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a consumer who might become a patient, was passed to a business to later be contacted',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          generation_type: {
            description: 'Indicating a type of reason why something came about',
            id: '/properties/properties/properties/event/properties/generation_type',
            type: 'string',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Lead Generated',
    type: 'object',
  };
  const message = {
    event: 'Lead Generated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Lead Generated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a consumer who might become a patient, was set in operation
 *
 * @param {LeadStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadStarted(
  props?: LeadStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a consumer who might become a patient, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event_source_url: {
            description: 'This indicates the referring URL',
            id: '/properties/properties/properties/event/properties/event_source_url',
            type: 'string',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          request_type: {
            description: 'Indicating a type of lead request',
            id: '/properties/properties/properties/event/properties/request_type',
            type: 'string',
          },
          start_type: {
            description: 'Indicating a type of starting action',
            id: '/properties/properties/properties/event/properties/start_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Lead Started',
    type: 'object',
  };
  const message = {
    event: 'Lead Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Lead Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a consumer who might become a patient, transitioned from being hidden to being shown
 *
 * @param {LeadUnmasked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadUnmasked(
  props?: LeadUnmasked,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a consumer who might become a patient, transitioned from being hidden to being shown',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          lead_id: {
            description: 'Indicating the unique id for a given lead',
            id: '/properties/properties/properties/event/properties/lead_id',
            type: 'string',
          },
          lead_source: {
            description: 'Indicating the source of a given lead',
            id: '/properties/properties/properties/event/properties/lead_source',
            type: 'string',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          unmask_type: {
            description:
              'Indicating the type of unmask action which took place',
            id: '/properties/properties/properties/event/properties/unmask_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Lead Unmasked',
    type: 'object',
  };
  const message = {
    event: 'Lead Unmasked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Lead Unmasked',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a consumer who might become a patient, was changed from an older version to a newer
 *
 * @param {LeadUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadUpdated(
  props?: LeadUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a consumer who might become a patient, was changed from an older version to a newer',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          lead_id: {
            description: 'Indicating the unique id for a given lead',
            id: '/properties/properties/properties/event/properties/lead_id',
            type: 'string',
          },
          lead_source: {
            description: 'Indicating the source of a given lead',
            id: '/properties/properties/properties/event/properties/lead_source',
            type: 'string',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          update_type: {
            description: 'Indicating a type of update',
            id: '/properties/properties/properties/event/properties/update_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Lead Updated',
    type: 'object',
  };
  const message = {
    event: 'Lead Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Lead Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a list of consumers who might become a patient, successfully transitioned from contact to customer
 *
 * @param {LeadsConverted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadsConverted(
  props?: LeadsConverted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a list of consumers who might become a patient, successfully transitioned from contact to customer',
    properties: {
      context: {},
      properties: {
        properties: {
          conversion_type: {
            description: 'Indicating a type of conversion',
            id: '/properties/properties/properties/event/properties/conversion_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          offers_redeemed: {
            description:
              'Indicating which offers (promotion_id) were used as part of a transactionan array of promotion_id values',
            id: '/properties/properties/properties/event/properties/offers_redeemed',
            type: 'array',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Leads Converted',
    type: 'object',
  };
  const message = {
    event: 'Leads Converted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Leads Converted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a list of consumers who might become a patient, was pulled down from the internet
 *
 * @param {LeadsDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadsDownloaded(
  props?: LeadsDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a list of consumers who might become a patient, was pulled down from the internet',
    properties: {
      context: {},
      properties: {
        properties: {
          download_type: {
            description: 'Indicating a type of download',
            id: '/properties/properties/properties/event/properties/download_type',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          lead_type: {
            description: 'Indicating the type of a given lead',
            id: '/properties/properties/properties/event/properties/lead_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Leads Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Leads Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Leads Downloaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, the act of authenticating into Alle, was set in operation
 *
 * @param {LoginStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginStarted(
  props?: LoginStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, the act of authenticating into Alle, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event_source_url: {
            description: 'This indicates the referring URL',
            id: '/properties/properties/properties/event/properties/event_source_url',
            type: 'string',
          },
          login_type: {
            description: 'Indicating a type of login',
            id: '/properties/properties/properties/event/properties/login_type',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          start_type: {
            description: 'Indicating a type of starting action',
            id: '/properties/properties/properties/event/properties/start_type',
            type: 'string',
          },
          url: {
            description:
              'Indicating the Uniform Resource Locator for a given internet location',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Login Started',
    type: 'object',
  };
  const message = {
    event: 'Login Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Login Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, the act of authenticating into Alle, was achieved
 *
 * @param {LoginSucceeded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSucceeded(
  props?: LoginSucceeded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, the act of authenticating into Alle, was achieved',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          login_type: {
            description: 'Indicating a type of login',
            id: '/properties/properties/properties/event/properties/login_type',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer_slug: {
            description:
              'Indicating a description of an offer which is correlated with this event firing',
            id: '/properties/properties/properties/event/properties/offer_slug',
            type: 'string',
          },
          success_type: {
            description: 'Indicating a type of success',
            id: '/properties/properties/properties/event/properties/success_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Login Succeeded',
    type: 'object',
  };
  const message = {
    event: 'Login Succeeded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Login Succeeded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, the act of expiring authentication with Alle, was achieved
 *
 * @param {LogoutSucceeded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logoutSucceeded(
  props?: LogoutSucceeded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, the act of expiring authentication with Alle, was achieved',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          logout_type: {
            description: 'Indicating a type of logout',
            id: '/properties/properties/properties/event/properties/logout_type',
            type: 'string',
          },
          success_type: {
            description: 'Indicating a type of success',
            id: '/properties/properties/properties/event/properties/success_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Logout Succeeded',
    type: 'object',
  };
  const message = {
    event: 'Logout Succeeded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Logout Succeeded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was attached to an account
 *
 * @param {OfferAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerAdded(
  props?: OfferAdded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was attached to an account',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Added',
    type: 'object',
  };
  const message = {
    event: 'Offer Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Added',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, became owned by an end user
 *
 * @param {OfferClaimed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerClaimed(
  props?: OfferClaimed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, became owned by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          claim_type: {
            description: 'Indicates a type of claim',
            id: '/properties/properties/properties/event/properties/claim_type',
            type: 'string',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          new_registration: {
            description:
              'Indicating whether or not a consumer was newly registered',
            id: '/properties/properties/properties/event/properties/new_registration',
            type: 'boolean',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Claimed',
    type: 'object',
  };
  const message = {
    event: 'Offer Claimed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Claimed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action
 *
 * @param {OfferFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerFailed(
  props?: OfferFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action",
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          error_reason: {
            description:
              'Verbose reason the transaction failed. Include any error codes at start of string, in brackets eg [43] Card Declined',
            id: '/properties/properties/properties/event/properties/error_reason',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          fail_type: {
            description: 'Indicating a type of failure',
            id: '/properties/properties/properties/event/properties/fail_type',
            type: 'string',
          },
          new_registration: {
            description:
              'Indicating whether or not a consumer was newly registered',
            id: '/properties/properties/properties/event/properties/new_registration',
            type: 'boolean',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Failed',
    type: 'object',
  };
  const message = {
    event: 'Offer Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Failed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was seen by an end user
 *
 * @param {OfferPresented} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerPresented(
  props?: OfferPresented,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          presentation_type: {
            description: 'Indicating a type of presentation',
            id: '/properties/properties/properties/event/properties/presentation_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          template_id: {
            description:
              'Indicating the unique identifier for a given template',
            id: '/properties/properties/properties/event/properties/template_id',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Presented',
    type: 'object',
  };
  const message = {
    event: 'Offer Presented',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Presented',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was exchanged as a form of value
 *
 * @param {OfferRedeemed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRedeemed(
  props?: OfferRedeemed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was exchanged as a form of value',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          checkout_id: {
            description:
              "Indicates a unique identifier for a given consumer checkout at a provider's office - ",
            id: '/properties/properties/properties/event/properties/checkout_id',
            type: 'string',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          redemption_type: {
            description: 'Indicating a type of redemption',
            id: '/properties/properties/properties/event/properties/redemption_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Redeemed',
    type: 'object',
  };
  const message = {
    event: 'Offer Redeemed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Redeemed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was subtracted or pulled back
 *
 * @param {OfferRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRemoved(
  props?: OfferRemoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was subtracted or pulled back',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          removal_type: {
            description: 'Indicating a type of removal',
            id: '/properties/properties/properties/event/properties/removal_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Removed',
    type: 'object',
  };
  const message = {
    event: 'Offer Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Removed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was solicited from a system or person
 *
 * @param {OfferRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRequested(
  props?: OfferRequested,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was solicited from a system or person',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          request_type: {
            description: 'Indicating a type of lead request',
            id: '/properties/properties/properties/event/properties/request_type',
            type: 'string',
          },
          step: {
            description:
              'Indicates the step number for a form filling activity',
            id: '/properties/properties/properties/event/properties/step',
            type: 'number',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Requested',
    type: 'object',
  };
  const message = {
    event: 'Offer Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Requested',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was distributed to others
 *
 * @param {OfferShared} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerShared(
  props?: OfferShared,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was distributed to others',
    properties: {
      context: {},
      properties: {
        properties: {
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          email_shared: {
            description:
              'Indicates the email address of a user who will be recipient of the share action',
            id: '/properties/properties/properties/event/properties/email_shared',
            type: 'string',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          share_type: {
            description: 'Indicating a type of share',
            id: '/properties/properties/properties/event/properties/share_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Shared',
    type: 'object',
  };
  const message = {
    event: 'Offer Shared',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Shared',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database
 *
 * @param {OfferSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSubmitted(
  props?: OfferSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          submission_type: {
            description: 'Indicating a type of submission',
            id: '/properties/properties/properties/event/properties/submission_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Submitted',
    type: 'object',
  };
  const message = {
    event: 'Offer Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was prevented from being shown
 *
 * @param {OfferSuppressed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSuppressed(
  props?: OfferSuppressed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was prevented from being shown',
    properties: {
      context: {},
      properties: {
        properties: {
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          reason: {
            description:
              'Indicates a human-readable explanation for why a given event happened',
            id: '/properties/properties/properties/event/properties/reason',
            type: 'string',
          },
          suppression_type: {
            description: 'Indicating a type of suppressing action',
            id: '/properties/properties/properties/event/properties/suppression_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Suppressed',
    type: 'object',
  };
  const message = {
    event: 'Offer Suppressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Suppressed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a webpage, was navigated horizontally or vertically through the viewport
 *
 * @param {PageScrolled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageScrolled(
  props?: PageScrolled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a webpage, was navigated horizontally or vertically through the viewport',
    properties: {
      context: {},
      properties: {
        properties: {
          content: {
            description: 'Encapsulates data regarding a piece of content',
            id: '/properties/properties/properties/content',
            properties: {
              content_campaign: {
                description: 'Indicates the campaign related to this content',
                id: '/properties/properties/properties/content/properties/content_campaign',
                type: 'string',
              },
              content_campaign_id: {
                description:
                  'Uniquely identifies this articles associated with a campaign, by way of a campaign_id',
                id: '/properties/properties/properties/content/properties/content_campaign_id',
                type: 'string',
              },
              content_campaign_name: {
                description: 'Indicates the campaign name behind this content',
                id: '/properties/properties/properties/content/properties/content_campaign_name',
                type: 'string',
              },
              content_concern_area: {
                description: 'Indicates the concern area of this content',
                id: '/properties/properties/properties/content/properties/content_concern_area',
                type: 'string',
              },
              content_headline: {
                description: 'Indicates the headline related to this content',
                id: '/properties/properties/properties/content/properties/content_headline',
                type: 'string',
              },
              content_id: {
                description:
                  "Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva",
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              content_journey: {
                description: 'Indicates the journey theme of this content',
                id: '/properties/properties/properties/content/properties/content_journey',
                type: 'string',
              },
              content_product: {
                description: 'Indicates the product(s) related to this content',
                id: '/properties/properties/properties/content/properties/content_product',
                type: 'string',
              },
              content_publish_date: {
                description: 'Indates the publish date of this content',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/content_publish_date',
                type: 'string',
              },
              content_slug: {
                description: 'Indicates the slug of this content',
                id: '/properties/properties/properties/content/properties/content_slug',
                type: 'string',
              },
              content_tag: {
                description: 'Indicates the tags related to this content',
                id: '/properties/properties/properties/content/properties/content_tag',
                type: 'string',
              },
              content_template: {
                description: 'Indicates the template used for this content',
                id: '/properties/properties/properties/content/properties/content_template',
                type: 'string',
              },
              content_type: {
                description: 'Indicates a type of content',
                id: '/properties/properties/properties/content/properties/content_type',
                type: 'string',
              },
              content_visual: {
                description: 'Indicates the visual related to this content',
                id: '/properties/properties/properties/content/properties/content_visual',
                type: 'string',
              },
              orientation: {
                description: 'Indicates the orientation of this content',
                id: '/properties/properties/properties/content/properties/orientation',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          page_type: {
            description: 'Indicating a type of page',
            id: '/properties/properties/properties/event/properties/page_type',
            type: 'string',
          },
          percent_scrolled: {
            description:
              'Indicating the % of the page/screen which was scrolled fires at 25/50/75/90 percent',
            id: '/properties/properties/properties/event/properties/percent_scrolled',
            type: 'number',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          scroll_type: {
            description: 'Indicating a type of scroll',
            id: '/properties/properties/properties/event/properties/scroll_type',
            type: 'string',
          },
          slug: {
            description:
              'Indicating the last part of a URL address that serves as a unique identifier of the page',
            id: '/properties/properties/properties/event/properties/slug',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Page Scrolled',
    type: 'object',
  };
  const message = {
    event: 'Page Scrolled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Page Scrolled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a code used to access Allē, was claimed to be out of one's mind
 *
 * @param {PasswordForgotten} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordForgotten(
  props?: PasswordForgotten,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Whereby, a code used to access Allē, was claimed to be out of one's mind",
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          forgetfulness_type: {
            description: 'Indicating a reason why something was forgotten',
            id: '/properties/properties/properties/event/properties/forgetfulness_type',
            type: 'string',
          },
          password_type: {
            description: 'Indicating a type of password',
            id: '/properties/properties/properties/event/properties/password_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Password Forgotten',
    type: 'object',
  };
  const message = {
    event: 'Password Forgotten',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Password Forgotten',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a code used to access Allē, was changed from an older version to a newer
 *
 * @param {PasswordUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordUpdated(
  props?: PasswordUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a code used to access Allē, was changed from an older version to a newer',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          password_type: {
            description: 'Indicating a type of password',
            id: '/properties/properties/properties/event/properties/password_type',
            type: 'string',
          },
          update_type: {
            description: 'Indicating a type of update',
            id: '/properties/properties/properties/event/properties/update_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Password Updated',
    type: 'object',
  };
  const message = {
    event: 'Password Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Password Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an Allē customer, contacted a provider via email, phone,or form.
 *
 * @param {ProviderContacted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function providerContacted(
  props?: ProviderContacted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an Allē customer, contacted a provider via email, phone,or form.',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Provider Contacted',
    type: 'object',
  };
  const message = {
    event: 'Provider Contacted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Provider Contacted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an Allē customer, was queried from our data
 *
 * @param {ProviderSearched} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function providerSearched(
  props?: ProviderSearched,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, an Allē customer, was queried from our data',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_type: {
            description: 'Indicating a type of provider',
            id: '/properties/properties/properties/provider_type',
            type: 'string',
          },
          query: {
            description:
              'an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed',
            id: '/properties/properties/properties/query',
            properties: {
              filter_data: {
                description:
                  'Freeform object used to qualify searches. Use name value pairs as specified by business needs',
                id: '/properties/properties/properties/query/properties/filter_data',
                type: 'object',
              },
              is_current_location: {
                description:
                  'Indicates if the current location of the device was used in this query',
                id: '/properties/properties/properties/query/properties/is_current_location',
                type: 'boolean',
              },
              radius: {
                description:
                  'Indicates the radius from the zip code related to this query',
                id: '/properties/properties/properties/query/properties/radius',
                type: 'number',
              },
              search_text: {
                description:
                  'Indicates the strings used to narrow down this query',
                id: '/properties/properties/properties/query/properties/search_text',
                type: 'string',
              },
              sort_type: {
                description:
                  "Indicates the type of sorting used as part of this query, e.g. 'ascending'",
                id: '/properties/properties/properties/query/properties/sort_type',
                type: 'string',
              },
              zip_code: {
                description: 'Indicates the zip code related to this query',
                id: '/properties/properties/properties/query/properties/zip_code',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          search_type: {
            description: 'Indicating a type of search',
            id: '/properties/properties/properties/search_type',
            type: 'string',
          },
          zip_code: {
            description:
              'Indicating a zip codeZip code needs to be string, not a number, eg 90808-1234 is perfectly valid, and Conneticut zips begin with 0',
            id: '/properties/properties/properties/zip_code',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Provider Searched',
    type: 'object',
  };
  const message = {
    event: 'Provider Searched',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Provider Searched',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an Allē customer, was seen by an end user
 *
 * @param {ProviderViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function providerViewed(
  props?: ProviderViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, an Allē customer, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event_source_url: {
            description: 'This indicates the referring URL',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_count: {
            description: '',
            id: '/properties/properties/properties/provider_count',
            type: 'integer',
          },
          provider_type: {
            description: 'Indicating a type of provider',
            id: '/properties/properties/properties/provider_type',
            type: 'string',
          },
          results_page: {
            description:
              'Indicating the pagination number of the search result experience',
            id: '/properties/properties/properties/results_page',
            type: 'string',
          },
          view_type: {
            description: 'Indicating a type of view',
            id: '/properties/properties/properties/view_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Provider Viewed',
    type: 'object',
  };
  const message = {
    event: 'Provider Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Provider Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a process of establishing an identity & unique account in a system, was finished/committed to a database
 *
 * @param {RegistrationCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationCompleted(
  props?: RegistrationCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a process of establishing an identity & unique account in a system, was finished/committed to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          consent_type: {
            description:
              'Indicates a the name of a thing which is requesting consent from a person or businessalle_accepted_sms | alle_accepted_tc | alle_accepted_hipaa | alle_accepted_promotions_email | alle_accepted_ads_target | alle_accepted_ads_providers | alle_accepted_ads_publishers | alle_accepted_ads_share | alle_accepted_location',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          consumer: {
            description: 'Encapsulates data regarding an Allē consumer',
            id: '/properties/properties/properties/consumer',
            properties: {
              '': {
                description: 'Indicates the email address of the consumer',
                id: '/properties/properties/properties/consumer/properties/',
                type: 'string',
              },
              address: {
                description:
                  "Encapsulates data regarding the consumer's address",
                id: '/properties/properties/properties/consumer/properties/address',
                properties: {
                  city: {
                    description:
                      'Indicates the city this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/city',
                    type: 'string',
                  },
                  country: {
                    description:
                      'Indicates the country this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/country',
                    type: 'string',
                  },
                  county: {
                    description:
                      'Indicates the county this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/county',
                    type: 'string',
                  },
                  state: {
                    description:
                      'Indicates the state of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/state',
                    type: 'string',
                  },
                  street: {
                    description:
                      'Indicates the street this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/street',
                    type: 'string',
                  },
                  zip_code: {
                    description:
                      'Indicates the zip code of this consumer, sits in @address object',
                    id: '/properties/properties/properties/consumer/properties/address/properties/zip_code',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              alle_id: {
                description:
                  'Uniquely identifies this consumer, by way of an alle ID',
                id: '/properties/properties/properties/consumer/properties/alle_id',
                type: 'string',
              },
              birthdate: {
                description:
                  "Encapsulates data regarding the consumer's birthdate",
                id: '/properties/properties/properties/consumer/properties/birthdate',
                properties: {
                  day: {
                    description:
                      'Indicates the birthdate day of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/day',
                    type: 'integer',
                  },
                  month: {
                    description:
                      'Indicates the birthdate month of this consumer, sits in @birthdate object',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/month',
                    type: 'integer',
                  },
                  year: {
                    description:
                      'Indicates the birthdate year of this consumer, sits in @birthdate object. IMPORTANT: For legal reasons you only collect this for legacy consumers, add prior to (need date). Consumers added after this date should not be tracked. This is per Legal Dept.',
                    id: '/properties/properties/properties/consumer/properties/birthdate/properties/year',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              email: {
                description: 'indicates the email address of the user.',
                id: '/properties/properties/properties/consumer/properties/email',
                type: 'string',
              },
              joined: {
                description:
                  'Encapsulates data regarding the dates in which the consumer joined our programs',
                id: '/properties/properties/properties/consumer/properties/joined',
                properties: {
                  alle: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/alle',
                    type: 'string',
                  },
                  bd: {
                    description:
                      'Indicates the alle join date of this consumer, sits in @joined object',
                    format: 'date-time',
                    id: '/properties/properties/properties/consumer/properties/joined/properties/bd',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: "encapsulates data regarding the consumer's name",
                id: '/properties/properties/properties/consumer/properties/name',
                properties: {
                  first: {
                    description: 'the consumers first (given) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/first',
                    type: 'string',
                  },
                  last: {
                    description: 'the consumers last (family) name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/last',
                    type: 'string',
                  },
                  middle: {
                    description: 'the consumers middle name',
                    id: '/properties/properties/properties/consumer/properties/name/properties/middle',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              phone: {
                description: 'Indicates the phone number of this consumer',
                id: '/properties/properties/properties/consumer/properties/phone',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer_slug: {
            description:
              'Indicating a description of an offer which is correlated with this event firing',
            id: '/properties/properties/properties/event/properties/offer_slug',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          registration_type: {
            description: 'Indicating a type of registration',
            id: '/properties/properties/properties/event/properties/registration_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Completed',
    type: 'object',
  };
  const message = {
    event: 'Registration Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a process of establishing an identity & unique account in a system, was set in operation
 *
 * @param {RegistrationStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationStarted(
  props?: RegistrationStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a process of establishing an identity & unique account in a system, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign: {
            description:
              'an object containing descriptive properties of a campaign',
            id: '/properties/properties/properties/campaign',
            properties: {
              campaign_id: {
                description:
                  'Uniquely identifies this instance of a campaign, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_id',
                type: 'string',
              },
              campaign_id_source: {
                description:
                  'Indicates the source of truth for generating the campaign_id',
                id: '/properties/properties/properties/campaign/properties/campaign_id_source',
                type: 'string',
              },
              campaign_name: {
                description: 'Indicates the name of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_name',
                type: 'string',
              },
              campaign_template_id: {
                description:
                  'Uniquely identifies this campaign template, as generated by the Grow backend',
                id: '/properties/properties/properties/campaign/properties/campaign_template_id',
                type: 'string',
              },
              campaign_type: {
                description: 'Indicates the type of this campaign',
                id: '/properties/properties/properties/campaign/properties/campaign_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              offer_activation: {
                description: 'Indicates the activation type of this offer',
                id: '/properties/properties/properties/offer/properties/offer_activation',
                type: 'string',
              },
              offer_benefactor: {
                description:
                  'IIndicates the institution which financially backed the offer',
                id: '/properties/properties/properties/offer/properties/offer_benefactor',
                type: 'string',
              },
              offer_brands: {
                description:
                  'Indicates the brands related to this offer. Please use brand codes, e.g. JUV, SKM, ALLE, CS',
                id: '/properties/properties/properties/offer/properties/offer_brands',
                type: 'array',
              },
              offer_claimed: {
                description:
                  'Indicates whether or not this offer has been claimed',
                id: '/properties/properties/properties/offer/properties/offer_claimed',
                type: 'string',
              },
              offer_expiration: {
                description: 'Indicates the expiration of this offer',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/offer_expiration',
                type: 'string',
              },
              offer_name: {
                description: 'Indicates the display name of this offer',
                id: '/properties/properties/properties/offer/properties/offer_name',
                type: 'string',
              },
              offer_name_internal: {
                description:
                  'Indicates the internal name of this offer, which may differ from the display name used by offer_name',
                id: '/properties/properties/properties/offer/properties/offer_name_internal',
                type: 'string',
              },
              offer_slug: {
                description: 'Indicates the slug of this offer',
                id: '/properties/properties/properties/offer/properties/offer_slug',
                type: 'string',
              },
              offer_type: {
                description: 'Indicates a type of offer',
                id: '/properties/properties/properties/offer/properties/offer_type',
                type: 'string',
              },
              offer_value: {
                description: 'Indicates the value of this offer in USD',
                id: '/properties/properties/properties/offer/properties/offer_value',
                type: 'string',
              },
              promotion_id: {
                description:
                  'Uniquely identifies this promotion, by way of the unique value generated from the promotion service/engine, not to be confused with the offer_id generated by offer builder',
                id: '/properties/properties/properties/offer/properties/promotion_id',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          registration_type: {
            description: 'Indicating a type of registration',
            id: '/properties/properties/properties/event/properties/registration_type',
            type: 'string',
          },
          start_type: {
            description: 'Indicating a type of starting action',
            id: '/properties/properties/properties/event/properties/start_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Started',
    type: 'object',
  };
  const message = {
    event: 'Registration Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a set of search results, was made more succinct by querying against some specific criteria
 *
 * @param {ResultsFiltered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resultsFiltered(
  props?: ResultsFiltered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a set of search results, was made more succinct by querying against some specific criteria',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          filter_type: {
            description: 'Indicating a type of filter',
            id: '/properties/properties/properties/filter_type',
            type: 'string',
          },
          query: {
            description:
              'an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed',
            id: '/properties/properties/properties/query',
            properties: {
              filter_data: {
                description:
                  'Freeform object used to qualify searches. Use name value pairs as specified by business needs',
                id: '/properties/properties/properties/query/properties/filter_data',
                type: 'object',
              },
              is_current_location: {
                description:
                  'Indicates if the current location of the device was used in this query',
                id: '/properties/properties/properties/query/properties/is_current_location',
                type: 'boolean',
              },
              radius: {
                description:
                  'Indicates the radius from the zip code related to this query',
                id: '/properties/properties/properties/query/properties/radius',
                type: 'number',
              },
              search_text: {
                description:
                  'Indicates the strings used to narrow down this query',
                id: '/properties/properties/properties/query/properties/search_text',
                type: 'string',
              },
              sort_type: {
                description:
                  "Indicates the type of sorting used as part of this query, e.g. 'ascending'",
                id: '/properties/properties/properties/query/properties/sort_type',
                type: 'string',
              },
              zip_code: {
                description: 'Indicates the zip code related to this query',
                id: '/properties/properties/properties/query/properties/zip_code',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          results_count: {
            description: '',
            id: '/properties/properties/properties/results_count',
            type: 'integer',
          },
          results_type: {
            description: 'Indicating a type of search results',
            id: '/properties/properties/properties/results_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Results Filtered',
    type: 'object',
  };
  const message = {
    event: 'Results Filtered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Results Filtered',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a set of search results, was seen by an end user
 *
 * @param {ResultsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resultsViewed(
  props?: ResultsViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, a set of search results, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          query: {
            description:
              'an object containing descriptive properties of how an end user whittled down a larger data set to find what they needed',
            id: '/properties/properties/properties/query',
            properties: {
              filter_data: {
                description:
                  'Freeform object used to qualify searches. Use name value pairs as specified by business needs',
                id: '/properties/properties/properties/query/properties/filter_data',
                type: 'object',
              },
              is_current_location: {
                description:
                  'Indicates if the current location of the device was used in this query',
                id: '/properties/properties/properties/query/properties/is_current_location',
                type: 'boolean',
              },
              radius: {
                description:
                  'Indicates the radius from the zip code related to this query',
                id: '/properties/properties/properties/query/properties/radius',
                type: 'number',
              },
              search_text: {
                description:
                  'Indicates the strings used to narrow down this query',
                id: '/properties/properties/properties/query/properties/search_text',
                type: 'string',
              },
              sort_type: {
                description:
                  "Indicates the type of sorting used as part of this query, e.g. 'ascending'",
                id: '/properties/properties/properties/query/properties/sort_type',
                type: 'string',
              },
              zip_code: {
                description: 'Indicates the zip code related to this query',
                id: '/properties/properties/properties/query/properties/zip_code',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          results_count: {
            description: '',
            id: '/properties/properties/properties/results_count',
            type: 'integer',
          },
          results_type: {
            description: 'Indicating a type of search results',
            id: '/properties/properties/properties/results_type',
            type: 'string',
          },
          view_type: {
            description: 'Indicating a type of view',
            id: '/properties/properties/properties/view_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Results Viewed',
    type: 'object',
  };
  const message = {
    event: 'Results Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Results Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a web or native experience, was engaged with resulting in moving from one page/experience to another
 *
 * @param {SiteNavigated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function siteNavigated(
  props?: SiteNavigated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a web or native experience, was engaged with resulting in moving from one page/experience to another',
    properties: {
      context: {},
      properties: {
        properties: {
          card_position: {
            description:
              'Indicates the position of a given content card relative to other content cards',
            id: '/properties/properties/properties/event/properties/card_position',
            type: 'string',
          },
          content: {
            description: 'Encapsulates data regarding a piece of content',
            id: '/properties/properties/properties/content',
            properties: {
              content_campaign: {
                description: 'Indicates the campaign related to this content',
                id: '/properties/properties/properties/content/properties/content_campaign',
                type: 'string',
              },
              content_campaign_id: {
                description:
                  'Uniquely identifies this articles associated with a campaign, by way of a campaign_id',
                id: '/properties/properties/properties/content/properties/content_campaign_id',
                type: 'string',
              },
              content_campaign_name: {
                description: 'Indicates the campaign name behind this content',
                id: '/properties/properties/properties/content/properties/content_campaign_name',
                type: 'string',
              },
              content_concern_area: {
                description: 'Indicates the concern area of this content',
                id: '/properties/properties/properties/content/properties/content_concern_area',
                type: 'string',
              },
              content_headline: {
                description: 'Indicates the headline related to this content',
                id: '/properties/properties/properties/content/properties/content_headline',
                type: 'string',
              },
              content_id: {
                description:
                  "Uniquely identifies this piece of content, by way of Contentful's Content_Id field, which is used to reference PRD# from Veeva",
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              content_journey: {
                description: 'Indicates the journey theme of this content',
                id: '/properties/properties/properties/content/properties/content_journey',
                type: 'string',
              },
              content_product: {
                description: 'Indicates the product(s) related to this content',
                id: '/properties/properties/properties/content/properties/content_product',
                type: 'string',
              },
              content_publish_date: {
                description: 'Indates the publish date of this content',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/content_publish_date',
                type: 'string',
              },
              content_slug: {
                description: 'Indicates the slug of this content',
                id: '/properties/properties/properties/content/properties/content_slug',
                type: 'string',
              },
              content_tag: {
                description: 'Indicates the tags related to this content',
                id: '/properties/properties/properties/content/properties/content_tag',
                type: 'string',
              },
              content_template: {
                description: 'Indicates the template used for this content',
                id: '/properties/properties/properties/content/properties/content_template',
                type: 'string',
              },
              content_type: {
                description: 'Indicates a type of content',
                id: '/properties/properties/properties/content/properties/content_type',
                type: 'string',
              },
              content_visual: {
                description: 'Indicates the visual related to this content',
                id: '/properties/properties/properties/content/properties/content_visual',
                type: 'string',
              },
              orientation: {
                description: 'Indicates the orientation of this content',
                id: '/properties/properties/properties/content/properties/orientation',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event_source_url: {
            description: 'This indicates the referring URL',
            id: '/properties/properties/properties/event/properties/event_source_url',
            type: 'string',
          },
          expiresAt: {
            description:
              'Indicating some form of an expiration - no idea where this is generated',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          navigation_type: {
            description: 'Indicating a type of navigation',
            id: '/properties/properties/properties/event/properties/navigation_type',
            type: 'string',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          section: {
            description: 'Indicating the name of a content section',
            id: '/properties/properties/properties/event/properties/section',
            type: 'string',
          },
          section_position: {
            description:
              'Indicating the position count of a given piece of content within a section',
            id: '/properties/properties/properties/event/properties/section_position',
            type: 'number',
          },
          site_type: {
            description: 'Indicating a type of site',
            id: '/properties/properties/properties/event/properties/site_type',
            type: 'string',
          },
          text: {
            description: 'Indicates a string of text relevant for this event',
            id: '/properties/properties/properties/event/properties/text',
            type: 'string',
          },
          url: {
            description:
              'Indicating the Uniform Resource Locator for a given internet location',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Site Navigated',
    type: 'object',
  };
  const message = {
    event: 'Site Navigated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Site Navigated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a please-wait screen or equivalent, was seen by an end user
 *
 * @param {TransitionViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transitionViewed(
  props?: TransitionViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a please-wait screen or equivalent, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          consent_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/consent_type',
            type: 'array',
          },
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Encapsulates data regarding general information about a provider',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  "Encapsulates data regarding the provider's addresses",
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description:
                      "Encapsulates data regarding the provider's billing address",
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description:
                          'Indicates the billing city of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the billing country of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the billing county of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the billing street of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the billing zip code of this provider, sits in @address.billing object',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      "Encapsulates data regarding the provider's office address",
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description:
                          'Indicates the office city this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the office country this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the office county this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the office street of this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the office zip code this provider, sits in @address.office object',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      "Encapsulates data regarding the provider's shipping address",
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description:
                          'Indicates the shipping city of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description:
                          'Indicates the shipping country of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description:
                          'Indicates the shipping county this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      street: {
                        description:
                          'Indicates the shipping street of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                      zip_code: {
                        description:
                          'Indicates the shipping zip code of this provider, sits in @address.shipping object',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/zip_code',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Indicates a freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'Indicates the email address of this provider',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description:
                  'Encapsulates data regarding the ids used to identify this provider',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'Uniquely identifies this business, by way of a business_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'Uniquely identifies this location, by way of a location_id provided by A4B',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'Uniquely identifies this provider, by way of a legacy provider_id from Alle for providers',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media_urls: {
                description:
                  "freeform object. Please use the format name_url, eg 'facebook_url', all lowercase, for the property followed by the actual url being used.",
                id: '/properties/properties/properties/provider/properties/media_urls',
                type: 'object',
              },
              name: {
                description: 'Indicates the name of this provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'Indicates the phone number of this provider. Expecting 10 digits, numbers only, no formatting.',
                id: '/properties/properties/properties/provider/properties/phone',
                type: 'string',
              },
              phone_extension: {
                description:
                  'Indicates the phone number extension of this provider',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              profile: {
                description:
                  'Encapsulates data regarding the provider profile related to a business location',
                id: '/properties/properties/properties/provider/properties/profile',
                properties: {
                  booking_url: {
                    description:
                      'Indicates the booking url of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/booking_url',
                    type: 'string',
                  },
                  channel: {
                    description:
                      'Indicates the channel of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/channel',
                    type: 'string',
                  },
                  consultation_cost: {
                    description:
                      'Indicates the consultation cost of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_cost',
                    type: 'string',
                  },
                  consultation_enabled: {
                    description:
                      'Indicates if consultations are enabled for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/consultation_enabled',
                    type: 'boolean',
                  },
                  contact_email_address: {
                    description:
                      'Indicates the contact email address of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_email_address',
                    type: 'string',
                  },
                  contact_info_added: {
                    description:
                      'Indicates if contact information was added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_info_added',
                    type: 'boolean',
                  },
                  contact_instagram_handle: {
                    description:
                      'Indicates the instagram handle of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/contact_instagram_handle',
                    type: 'string',
                  },
                  hide_consultation_form: {
                    description:
                      'Indicates if the consultation form of this business location is hidden or shown, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hide_consultation_form',
                    type: 'boolean',
                  },
                  hours_added: {
                    description:
                      'Indicates if hours of operation were updated for this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/hours_added',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'Indicates the consultation fee can be contributed towards treatment cost at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  location_count: {
                    description:
                      'Indicates the number of locations which were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/location_count',
                    type: 'integer',
                  },
                  locations_updated: {
                    description:
                      'Indicates which locations were updated, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/locations_updated',
                    type: 'array',
                  },
                  num_days_closed: {
                    description:
                      'Indicates the number of days closed at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'Indicates the number of days open at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'Indicates the ___ of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_days_set',
                    type: 'integer',
                  },
                  num_gallery_photos: {
                    description:
                      'Indicates the number of gallery photos shown at this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_gallery_photos',
                    type: 'integer',
                  },
                  num_products: {
                    description:
                      'Total number of products being shown in the profile',
                    id: '/properties/properties/properties/provider/properties/profile/properties/num_products',
                    type: 'integer',
                  },
                  phone_number: {
                    description:
                      'Indicates the phone number of this business location, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/phone_number',
                    type: 'string',
                  },
                  photos_added: {
                    description: 'Indicating whether or not photos were added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/photos_added',
                    type: 'boolean',
                  },
                  practitioner_added: {
                    description:
                      'Indicating whether or not a practitioner was added',
                    id: '/properties/properties/properties/provider/properties/profile/properties/practitioner_added',
                    type: 'boolean',
                  },
                  profile_completeness: {
                    description:
                      'Indicates the level of completeness of this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/profile_completeness',
                    type: 'number',
                  },
                  publish_type: {
                    description: 'Indicating a type of publish',
                    id: '/properties/properties/properties/provider/properties/profile/properties/publish_type',
                    type: 'string',
                  },
                  send_requests_to_email: {
                    description:
                      'Indicates the email where lead requests are sent for this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/send_requests_to_email',
                    type: 'string',
                  },
                  success: {
                    description:
                      'Indicates the success of a publish event, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/success',
                    type: 'boolean',
                  },
                  treatments_added: {
                    description:
                      'Indicates if treatments were added to this profile, sits in @profile object',
                    id: '/properties/properties/properties/provider/properties/profile/properties/treatments_added',
                    type: 'boolean',
                  },
                },
                required: [],
                type: 'object',
              },
              url: {
                description: 'Indicates the website url of this provider',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/event/properties/tactic_summary',
            type: 'string',
          },
          transition_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/transition_type',
            type: 'string',
          },
          view_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/view_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Transition Viewed',
    type: 'object',
  };
  const message = {
    event: 'Transition Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Transition Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an embedded A/V asset, reached 90% of conclusion
 *
 * @param {VideoEnded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoEnded(
  props?: VideoEnded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, an embedded A/V asset, reached 90% of conclusion',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          title: {
            description: '',
            id: '/properties/properties/properties/event/properties/title',
            type: 'string',
          },
          url: {
            description: '',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
          video_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/video_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Video Ended',
    type: 'object',
  };
  const message = {
    event: 'Video Ended',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Video Ended',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an embedded A/V asset, was manually suspended by the viewer.
 *
 * @param {VideoPaused} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPaused(
  props?: VideoPaused,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an embedded A/V asset, was manually suspended by the viewer.',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          title: {
            description: '',
            id: '/properties/properties/properties/event/properties/title',
            type: 'string',
          },
          url: {
            description: '',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
          video_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/video_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Video Paused',
    type: 'object',
  };
  const message = {
    event: 'Video Paused',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Video Paused',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an embedded A/V asset, was navigated horizontally or vertically through the viewport
 *
 * @param {VideoScrolled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoScrolled(
  props?: VideoScrolled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an embedded A/V asset, was navigated horizontally or vertically through the viewport',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          title: {
            description: '',
            id: '/properties/properties/properties/event/properties/title',
            type: 'string',
          },
          url: {
            description: '',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
          video_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/video_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Video Scrolled',
    type: 'object',
  };
  const message = {
    event: 'Video Scrolled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Video Scrolled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an embedded A/V asset, was set in operation
 *
 * @param {VideoStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoStarted(
  props?: VideoStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, an embedded A/V asset, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Encapsulates data regarding general information about this event',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) which triggered the event. Can be human-readable as necessary.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              event_step: {
                description:
                  'Can be used to indicate sub-stages of complex events such as Registrations or Purchases when UI requirements have split the logical Event into two or more sub-steps, for example "Registration: Billing Info Page" might be used on a Site Navigated event to detect dropoff rates.',
                id: '/properties/properties/properties/event/properties/event_step',
                type: 'string',
              },
              event_type: {
                description:
                  "Used when an Event Name needs to be clarified, e.g. 'Element Engaged' might have an event type of 'aem accordion'",
                id: '/properties/properties/properties/event/properties/event_type',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video\"",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the team may want to record, which isn't covered elsewhere.",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions or server | page for automated events like timeouts or threshold notices.',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              user_status: {
                description:
                  'Indicates whether or not this user is logged in or not. logged|not logged',
                id: '/properties/properties/properties/event/properties/user_status',
                type: 'string',
              },
              user_type: {
                description:
                  'Indicates the end user generating this event, eg consumer|provider||associate. Typically used when the Event was triggered on behalf of another party, eg when an associate registers a consumer, this would be set to associate.',
                id: '/properties/properties/properties/event/properties/user_type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: '',
            id: '/properties/properties/properties/event/properties/expiresAt',
            type: 'number',
          },
          start_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/start_type',
            type: 'string',
          },
          title: {
            description: '',
            id: '/properties/properties/properties/event/properties/title',
            type: 'string',
          },
          url: {
            description: '',
            id: '/properties/properties/properties/event/properties/url',
            type: 'string',
          },
          video_type: {
            description: '',
            id: '/properties/properties/properties/event/properties/video_type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Video Started',
    type: 'object',
  };
  const message = {
    event: 'Video Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Video Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Whereby, an automated phone call, was attached to an account
   *
   * @param {AutocallAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  autocallAdded,
  /**
   * Whereby, an automated phone call, was accepted by the end user or system
   *
   * @param {AutocallApproved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  autocallApproved,
  /**
   * Whereby, an automated phone call, was solicited from a system or person
   *
   * @param {AutocallRequested} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  autocallRequested,
  /**
   * Whereby, the permission for something to happen or agreement to do something, was accepted by the end user or system
   *
   * @param {ConsentApproved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  consentApproved,
  /**
   * Whereby, The permission for something to happen or agreement to do something, was denied
   *
   * @param {ConsentDeclined} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  consentDeclined,
  /**
   * Whereby, a message or article made available by Allē, was pulled down from the internet
   *
   * @param {ContentDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentDownloaded,
  /**
   * Whereby, The permission for something to happen or agreement to do something, was seen by an end user
   *
   * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentScrolled,
  /**
   * Whereby, a message or article made available by Allē, was seen by an end user
   *
   * @param {ContentViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentViewed,
  /**
   * Whereby, a distinct part of our user interface, was interacted with
   *
   * @param {ElementEngaged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  elementEngaged,
  /**
   * Whereby, a process of signing up for a program within a parent system, was finished/committed to a database
   *
   * @param {EnrollmentCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  enrollmentCompleted,
  /**
   * Whereby, a process of signing up for a program within a parent system, was set in operation
   *
   * @param {EnrollmentStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  enrollmentStarted,
  /**
   * Whereby, a process of signing up for a program within a parent system, was changed from an older version to a newer
   *
   * @param {EnrollmentUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  enrollmentUpdated,
  /**
   * Whereby, an instruction that is either not recognized by an operating system or is in violation of rules, was invoked by our system
   *
   * @param {ErrorTriggered} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorTriggered,
  /**
   * Whereby, assistance, was solicited from a system or person
   *
   * @param {HelpRequested} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpRequested,
  /**
   * Whereby, data, was acquired
   *
   * @param {InformationCollected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  informationCollected,
  /**
   * Whereby, data, was pulled down from the internet
   *
   * @param {InformationDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  informationDownloaded,
  /**
   * Whereby, a consumer who might become a patient, was replicated to be pasted somewhere else
   *
   * @param {LeadCopied} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadCopied,
  /**
   * Whereby, a consumer who might become a patient, was passed to a business to later be contacted
   *
   * @param {LeadGenerated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadGenerated,
  /**
   * Whereby, a consumer who might become a patient, was set in operation
   *
   * @param {LeadStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadStarted,
  /**
   * Whereby, a consumer who might become a patient, transitioned from being hidden to being shown
   *
   * @param {LeadUnmasked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadUnmasked,
  /**
   * Whereby, a consumer who might become a patient, was changed from an older version to a newer
   *
   * @param {LeadUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadUpdated,
  /**
   * Whereby, a list of consumers who might become a patient, successfully transitioned from contact to customer
   *
   * @param {LeadsConverted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadsConverted,
  /**
   * Whereby, a list of consumers who might become a patient, was pulled down from the internet
   *
   * @param {LeadsDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadsDownloaded,
  /**
   * Whereby, the act of authenticating into Alle, was set in operation
   *
   * @param {LoginStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginStarted,
  /**
   * Whereby, the act of authenticating into Alle, was achieved
   *
   * @param {LoginSucceeded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginSucceeded,
  /**
   * Whereby, the act of expiring authentication with Alle, was achieved
   *
   * @param {LogoutSucceeded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  logoutSucceeded,
  /**
   * Whereby, an incentive from Allergan or a provider, was attached to an account
   *
   * @param {OfferAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerAdded,
  /**
   * Whereby, an incentive from Allergan or a provider, became owned by an end user
   *
   * @param {OfferClaimed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerClaimed,
  /**
   * Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action
   *
   * @param {OfferFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerFailed,
  /**
   * Whereby, an incentive from Allergan or a provider, was seen by an end user
   *
   * @param {OfferPresented} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerPresented,
  /**
   * Whereby, an incentive from Allergan or a provider, was exchanged as a form of value
   *
   * @param {OfferRedeemed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerRedeemed,
  /**
   * Whereby, an incentive from Allergan or a provider, was subtracted or pulled back
   *
   * @param {OfferRemoved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerRemoved,
  /**
   * Whereby, an incentive from Allergan or a provider, was solicited from a system or person
   *
   * @param {OfferRequested} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerRequested,
  /**
   * Whereby, an incentive from Allergan or a provider, was distributed to others
   *
   * @param {OfferShared} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerShared,
  /**
   * Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database
   *
   * @param {OfferSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerSubmitted,
  /**
   * Whereby, an incentive from Allergan or a provider, was prevented from being shown
   *
   * @param {OfferSuppressed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerSuppressed,
  /**
   * Whereby, a webpage, was navigated horizontally or vertically through the viewport
   *
   * @param {PageScrolled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageScrolled,
  /**
   * Whereby, a code used to access Allē, was claimed to be out of one's mind
   *
   * @param {PasswordForgotten} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  passwordForgotten,
  /**
   * Whereby, a code used to access Allē, was changed from an older version to a newer
   *
   * @param {PasswordUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  passwordUpdated,
  /**
   * Whereby, an Allē customer, contacted a provider via email, phone,or form.
   *
   * @param {ProviderContacted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  providerContacted,
  /**
   * Whereby, an Allē customer, was queried from our data
   *
   * @param {ProviderSearched} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  providerSearched,
  /**
   * Whereby, an Allē customer, was seen by an end user
   *
   * @param {ProviderViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  providerViewed,
  /**
   * Whereby, a process of establishing an identity & unique account in a system, was finished/committed to a database
   *
   * @param {RegistrationCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationCompleted,
  /**
   * Whereby, a process of establishing an identity & unique account in a system, was set in operation
   *
   * @param {RegistrationStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationStarted,
  /**
   * Whereby, a set of search results, was made more succinct by querying against some specific criteria
   *
   * @param {ResultsFiltered} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resultsFiltered,
  /**
   * Whereby, a set of search results, was seen by an end user
   *
   * @param {ResultsViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resultsViewed,
  /**
   * Whereby, a web or native experience, was engaged with resulting in moving from one page/experience to another
   *
   * @param {SiteNavigated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  siteNavigated,
  /**
   * Whereby, a please-wait screen or equivalent, was seen by an end user
   *
   * @param {TransitionViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  transitionViewed,
  /**
   * Whereby, an embedded A/V asset, reached 90% of conclusion
   *
   * @param {VideoEnded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  videoEnded,
  /**
   * Whereby, an embedded A/V asset, was manually suspended by the viewer.
   *
   * @param {VideoPaused} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  videoPaused,
  /**
   * Whereby, an embedded A/V asset, was navigated horizontally or vertically through the viewport
   *
   * @param {VideoScrolled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  videoScrolled,
  /**
   * Whereby, an embedded A/V asset, was set in operation
   *
   * @param {VideoStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  videoStarted,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/allergan-dev/protocols/tracking-plans/rs_2KkO5B9Z6JhlHJL9stFKQgNw83h`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
