import React from 'react';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import queryString from 'query-string';

interface OptimizelyFeatureProps {
  children?: React.ReactNode;
  feature: string;
}

const buildAlleFeatureOverrides = () => {
  let overridesStr = '';

  if (process.env.REACT_APP_OPTIMIZELY_FEATURE_OVERRIDES) {
    overridesStr = process.env.REACT_APP_OPTIMIZELY_FEATURE_OVERRIDES;
  }

  // TODO: Stop referencing global location
  // eslint-disable-next-line no-restricted-globals
  const qsEnableFeatures = queryString.parse(location.search)
    .enableFeatures as string;

  if (overridesStr !== '' && qsEnableFeatures) {
    overridesStr += `,${qsEnableFeatures}`;
  } else if (qsEnableFeatures) {
    overridesStr = qsEnableFeatures;
  }

  return overridesStr;
};

const alleFeatureOverrides = buildAlleFeatureOverrides();

const hasAlleFeatureOverride = (feature: string): boolean =>
  alleFeatureOverrides !== '' && alleFeatureOverrides.indexOf(feature) > -1;

const AlleFeatureFlag: React.FunctionComponent<OptimizelyFeatureProps> = ({
  children,
  feature,
}) => (
  <OptimizelyFeature feature={feature}>
    {(isEnabled) =>
      isEnabled || hasAlleFeatureOverride(feature) ? <>{children}</> : <></>
    }
  </OptimizelyFeature>
);

export { AlleFeatureFlag, hasAlleFeatureOverride };
