import { useSplitTreatments } from '@splitsoftware/splitio-react';
import SplitIO from '@splitsoftware/splitio/types/splitio';
import { deepmerge } from 'deepmerge-ts';

import { useAlleTreatmentOverrides } from '../useAlleTreatmentOverrides';
import { TreatmentsWithConfig } from '../types';

interface useAlleTreatmentsProps<T extends string> {
  splitNames: T[];
  attributes?: SplitIO.Attributes;
  key?: SplitIO.SplitKey;
  enableOverrides?: boolean;
}

const useAlleTreatments = <T extends string>({
  splitNames,
  attributes,
  key,
  enableOverrides = true,
}: useAlleTreatmentsProps<T>): TreatmentsWithConfig<T> => {
  const { treatmentAttributeOverrides, getAlleTreatmentsWithOverrides } =
    useAlleTreatmentOverrides({
      enableOverrides,
    });

  const rawTreatments: TreatmentsWithConfig = useSplitTreatments({
    names: splitNames,
    attributes: deepmerge(attributes, treatmentAttributeOverrides),
    splitKey: key,
  }).treatments;

  return getAlleTreatmentsWithOverrides(rawTreatments);
};

export { useAlleTreatments };
