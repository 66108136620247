import { ApolloProvider } from '@apollo/react-hooks';

import React from 'react';

// shared SDK
import { client } from '@allergan-data-labs/shared-sdk/src/client';

// shared components
import { LinkProvider } from '@allergan-data-labs/component-library/src/core/linkContext';
import { StyledComponentsThemeProvider } from '@allergan-data-labs/component-library/src/styledComponents/themeProvider';

import { SegmentProvider } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';

// consumer components
import { ThemeProvider } from './components/themeProvider';

// utils
import { routeConfig } from './routeConfig';
import { __DEV__ } from './application.config';

// feature flags
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';
import { coreShared } from '@allergan-data-labs/split-feature-flag/src/coreSquad';

// TODO pull this up
import { AlleMetadata } from './utils/alleMetadata';

// Temp Wrapper to be removed after the migration is complete
const ConsumerSegmentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { [coreShared.segmentAST3Migration]: ast3TrackingPlan } =
    useAlleTreatments({
      splitNames: [coreShared.segmentAST3Migration],
    });

  const isNewTrackingPlanEnabled = ast3TrackingPlan.treatment === 'on';
  React.useEffect(() => {
    if (__DEV__) {
      console.log('⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇');
      console.log('AST3 Plan Enabled:', isNewTrackingPlanEnabled);
      console.log('⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆');
    }
  }, [isNewTrackingPlanEnabled]);

  return (
    <SegmentProvider enableNewTrackingPlan={isNewTrackingPlanEnabled}>
      {children}
    </SegmentProvider>
  );
};

const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <AlleMetadata />
      <ApolloProvider client={client}>
        <ConsumerSegmentWrapper>
          <LinkProvider routes={routeConfig}>
            <ThemeProvider>
              <StyledComponentsThemeProvider>
                {children}
              </StyledComponentsThemeProvider>
            </ThemeProvider>
          </LinkProvider>
        </ConsumerSegmentWrapper>
      </ApolloProvider>
    </>
  );
};

export { Providers };
