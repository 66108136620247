import React from 'react';
import styled from 'styled-components';
import { ErrorPage } from './errorPage';
import { colors } from '../../core/colors';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: ${colors.white};
`;

interface ErrorPage503Props {}

const ErrorPage503: React.FunctionComponent<ErrorPage503Props> = () => (
  <Container>
    <ErrorPage
      title="503"
      subtitle={
        <span>
          We&apos;re currently undergoing maintenance,
          <br />
          please check back again soon.
        </span>
      }
    />
  </Container>
);

export { ErrorPage503 };
