import React from 'react';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { deviceDetect } from 'react-device-detect';

import { DefaultTheme } from '@allergan-data-labs/universal-component-library/src/theme/theme';

interface StyledComponentsThemeProviderProps {
  children?: React.ReactNode;
  themeOverrides?: Object;
}

const StyledComponentsThemeProvider: React.FC<
  StyledComponentsThemeProviderProps
> = ({ themeOverrides = {}, children }) => {
  const combinedTheme = { ...DefaultTheme, ...themeOverrides };

  const device = deviceDetect();

  return (
    <StyleSheetManager
      disableCSSOMInjection={device?.userAgent?.includes(
        'Prerender (+https://github.com/prerender/prerender)'
      )}
    >
      <ThemeProvider theme={combinedTheme}>
        <>{children}</>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export { StyledComponentsThemeProvider };
