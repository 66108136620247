import React from 'react';
import { Helmet } from 'react-helmet';

interface AlleMetadataProps {
  title?: string;
  description?: string;
  type?: string;
  image?: string;
  imageAlt?: string;
  url?: string;
}

const defaultData: AlleMetadataProps = {
  title: 'Allē.com - A loyalty program uniquely designed for you.',
  description:
    'Allē rewards you with points on Allergan Aesthetics brands and a variety of in-office treatments from other brands too.',
  type: 'website',
  image:
    'https://media.alle.com/image/upload/v1614964598/alle/consumer/homepage/Alle_FB_lpieih.png',
  imageAlt: 'Allē logo',
  url: 'https://alle.com/',
};

const AlleMetadata = (props: AlleMetadataProps) => {
  return (
    <Helmet>
      <title>{props?.title || 'Allē'}</title>
      <meta
        name="description"
        content={props?.description || defaultData.description}
      />
      <meta property="og:title" content={props?.title || defaultData.title} />
      <meta property="og:type" content={props?.type || defaultData.type} />
      <meta
        property="og:description"
        content={props?.description || defaultData.description}
      />
      <meta property="og:image" content={props?.image || defaultData.image} />
      <meta
        property="og:image:alt"
        content={props?.imageAlt || defaultData.imageAlt}
      />
      <meta property="og:site_name" content="Allē.com" />
      <meta property="og:url" content={props?.url || defaultData.url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={props?.title || defaultData.title} />
      <meta
        name="twitter:description"
        content={props?.description || defaultData.description}
      />
      <meta name="twitter:image" content={props?.image || defaultData.image} />
      <meta
        name="twitter:image:alt"
        content={props?.imageAlt || defaultData.imageAlt}
      />
      <meta name="twitter:url" content={props?.url || defaultData.url} />
    </Helmet>
  );
};

export { AlleMetadata };
