import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { screenSizes } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: screenSizes.mobile,
      md: screenSizes.tablet,
      lg: screenSizes.desktop,
      xl: screenSizes.largeDesktop,
    },
  },
});

const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export { ThemeProvider };
