const coreShared = {
  /**
   * ON = AST3 tracking plan is enabled aka `trackConsumer`
   * OFF = Deprecated tracking plan enabled aka `trackDeprecated`
   */
  segmentAST3Migration: 'segment_ast3TrackingPlan_frontend',
  a4bUpgradeNotification: 'a4b_upgradeNotification_frontend',
  a4bUpgradeNotificationV2: 'a4b_upgradeNotificationV2_frontend',
} as const;

export { coreShared };
