import React from 'react';

// shared components
import { ReactLazyPreload } from '@allergan-data-labs/component-library/src/core/preload';

// constants
import { Routes } from './constants/routes';

export interface RouteConfig {
  path: Routes;
  title?: string;
  header?: React.FunctionComponent<any>;
  component: React.FunctionComponent<any>;
  secure: boolean;
  hideFooter?: boolean;
  exact: boolean;
}

const LandingView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AboutView' */ './views/landing/landingView'
  ).then((mod) => ({
    default: mod.LandingView,
  }))
);

const baseUnsecureRoutes: RouteConfig[] = [
  {
    path: Routes.home,
    title: 'Allē - Landing',
    component: LandingView,
    secure: false,
    exact: true,
  },
];

const routeConfig: RouteConfig[] = [...baseUnsecureRoutes];

export { routeConfig };
