import React from 'react';

import typewriterDeprecated from '../segment-tracking-plan-deprecated/generated';
import typewriterConsumer from '../segment-tracking-plan/generated';

import {
  ExternalIds,
  IdentifyTraitsOptions,
  SegmentContextValue,
  SegmentTraits,
} from './segmentTypes';
import { identifyTraits as segmentIdentifyTraits } from './identifyTraits';

import {
  segmentPublicKey,
  __CMS_PREVIEW__ as isCmsPreviewEnv,
  __SEGMENT_DEBUGGER_ENABLED__,
} from '../application.config';
import { useSyncSegmentIdentity } from './useSyncSegmentIdentity';

// middlewares
import { fullStoryMiddleware } from './fullStoryMiddleware';
import { alleDebuggingMiddleware } from './alleDebuggingMiddleware';
import { formatPhoneNumberMiddleware } from './formatPhoneNumberMiddleware';
import { utmValueMiddleware } from './utmValuesMiddleware';
import { fbcFbpValueMiddleware } from './fbcFbpValueMiddleware';

const SegmentContext = React.createContext<SegmentContextValue>({
  page: () => {},
  trackDeprecated: () => undefined,
  trackConsumer: () => undefined,
  identifyTraits: () => undefined,
  syncSegmentIdentity: () => undefined,
});

type SegmentProviderProps = {
  children: React.ReactNode;
  enableNewTrackingPlan?: boolean;
};

const trackConsumerNoop = Object.keys(typewriterConsumer).reduce((acc, key) => {
  return {
    ...acc,
    [key]: (...args: any[]) => {
      if (__SEGMENT_DEBUGGER_ENABLED__) {
        console.group('Segment Debugger');
        console.log(key, 'future track event suppressed', args);
        console.groupEnd();
      }
    },
  };
}, {});

const trackDeprecatedNoop = Object.keys(typewriterDeprecated).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: (...args: any[]) => {
        if (__SEGMENT_DEBUGGER_ENABLED__) {
          console.group('Segment Debugger');
          console.log(key, 'Deprecated track event suppressed', args);
          console.groupEnd();
        }
      },
    };
  },
  {}
);

const SegmentProvider = ({
  enableNewTrackingPlan = false,
  children,
}: SegmentProviderProps) => {
  React.useEffect(() => {
    try {
      if (!segmentPublicKey) {
        return;
      }

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(formatPhoneNumberMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(utmValueMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(alleDebuggingMiddleware);

      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(fbcFbpValueMiddleware);

      // @ts-ignore
      window?.analytics?.addDestinationMiddleware?.('FullStory', [
        fullStoryMiddleware,
      ]);

      if (!isCmsPreviewEnv) {
        window?.analytics?.load?.(segmentPublicKey);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const page = React.useCallback((name?: string, properties?: Object) => {
    try {
      window?.analytics?.page?.(name, properties);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const trackDeprecated = React.useCallback(() => {
    if (enableNewTrackingPlan) {
      return trackDeprecatedNoop as unknown as typeof typewriterDeprecated;
    }

    return typewriterDeprecated;
  }, [enableNewTrackingPlan]);

  const trackConsumer = React.useCallback(() => {
    if (enableNewTrackingPlan) {
      return typewriterConsumer;
    }

    return trackConsumerNoop as unknown as typeof typewriterConsumer;
  }, [enableNewTrackingPlan]);

  const identifyTraits = React.useCallback(
    (
      traits: SegmentTraits,
      externalIds?: ExternalIds,
      options?: IdentifyTraitsOptions
    ) => segmentIdentifyTraits(traits, externalIds, options),
    []
  );
  const syncSegmentIdentity = useSyncSegmentIdentity();

  return (
    <SegmentContext.Provider
      value={{
        page,
        trackDeprecated,
        trackConsumer,
        identifyTraits,
        syncSegmentIdentity,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

const useSegment = () => React.useContext(SegmentContext);

export { SegmentProvider, useSegment };
