import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ErrorBoundary } from '@allergan-data-labs/component-library/src/errorBoundary/errorBoundary';
import { ErrorPage503 } from '@allergan-data-labs/component-library/src/components/errorPages/errorPage503';
import { LoadingScreen } from '@allergan-data-labs/component-library/src/components/loadingScreen';
import { AlleDownPage } from '@allergan-data-labs/component-library/src/components/errorPages/alleDownPage';

import { routeConfig, RouteConfig } from './routeConfig';
import { Providers } from './providers';
import { App } from './app';
import {
  IS_BOTOX_DOMAIN,
  isAlleDown,
  isInMaintenanceMode,
} from './application.config';

const AppRouter = () => {
  if (isInMaintenanceMode) {
    return <ErrorPage503 />;
  }

  if (isAlleDown) {
    return <AlleDownPage />;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ErrorBoundary>
        <React.Suspense fallback={<LoadingScreen />}>
          <Providers>
            <App>
              <div
                css={`
                  position: relative;
                `}
              >
                <Switch>
                  {routeConfig.map((route: RouteConfig) => (
                    <Route
                      key="no-key"
                      path={route.path}
                      exact={route.exact}
                      render={(props) => {
                        const Component = route.component as any;
                        return (
                          <>
                            <Helmet
                              title={route.title ? route.title : 'Allē'}
                            />
                            <Component
                              {...props}
                              title={IS_BOTOX_DOMAIN ? 'botox' : 'juvederm'}
                            />
                          </>
                        );
                      }}
                    />
                  ))}
                </Switch>
              </div>
            </App>
          </Providers>
        </React.Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export { AppRouter };
